import React from 'react'
import {
  Modal, FilledButton
} from '@jsluna/react'
import { PropTypes } from 'prop-types'

const TCsChangedModal = (props) => {
  const {
    isOpen, continueClick
  } = props

  return (
    <Modal open={isOpen} fullScreen restrictClose>
      <h2 className="ln-u-flush-sides">Terms and conditions</h2>
      <div className="ln-u-padding-top ln-u-padding-bottom*2">
        <p>The terms and conditions have changed.</p>
        <p className="ln-u-margin-top*3">You will need to accept them before you can sign in.</p>
      </div>
      <FilledButton fullWidth onClick={continueClick}>Continue to T&Cs</FilledButton>
    </Modal>
  )
}

TCsChangedModal.propTypes = {
  isOpen: PropTypes.bool,
  continueClick: PropTypes.func.isRequired
}

TCsChangedModal.defaultProps = {
  isOpen: false
}

export default TCsChangedModal
