import getMobileOperatingSystem from './mobileHelper'
import { store } from '../store'
import { setUser } from '../store/loggedInUser/actions'

const setToken = (token, expires) => {
  const loginToken = { token, expires }
  sessionStorage.setItem('bearerToken', JSON.stringify(loginToken))
}

const removeToken = () => {
  sessionStorage.removeItem('bearerToken')
}

const getToken = () => sessionStorage.getItem('bearerToken')

const setRememberMe = remember => localStorage.setItem('rememberMe', remember)

const getRememberMe = () => localStorage.getItem('rememberMe')

const clearAuthCookie = () => {
  const domain = document.domain
  const path = '/'
  const name = 'SignInBookSession'
  document.cookie = name + "=; expires=" + +new Date() + "; domain=" + domain + "; path=" + path
}

const isTokenExpired = () => {
  const token = getToken()
  if (token && token.length > 0) {
    const tokenExpiry = JSON.parse(token).expires

    // Identity returns the epoch in seconds and javascript needs it in millisconds
    if (new Date(tokenExpiry * 1000).toISOString() > new Date().toISOString()) {
      return false
    }
    sessionStorage.removeItem('bearerToken')
    const remember = getRememberMe()

    if (remember === 'false' && getMobileOperatingSystem() !== 'iOS') {
      clearAuthCookie()
      store.dispatch(setUser(null))
    }
    return true
  }
  return true
}

export { setToken, getToken, isTokenExpired, setRememberMe, removeToken, getRememberMe }
