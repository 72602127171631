import { connect } from 'react-redux'
import Passes from './Passes'
import { setHeaderBurger, setHeaderSignOut } from '../../../store/header/actions'
import { setSelectedPass, removePassFromVisitPasses } from '../../../store/visit/actions'
import { setPassTypes } from '../../../store/passTypes/actions'

const mapStateToProps = state => ({
  selectedWorkOrder: state.visit.openVisit.workOrderNumber,
  visitId: state.visit.openVisit.id,
  visitPasses: state.visit.openVisit.visitPasses,
  passTypes: state.passTypes.passTypes,
  username: state.loggedInUser.user.username,
  site: state.sainsburysSites.site
})

const mapDispatchToProps = dispatch => ({
  setHeaderBurger: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setVisitPass: (visitPass) => {
    dispatch(setSelectedPass(visitPass))
  },
  deleteVisitPass: (visitPass) => {
    dispatch(removePassFromVisitPasses(visitPass))
  },
  setPassTypes: (passTypes) => {
    dispatch(setPassTypes(passTypes))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Passes)
