const actions = {
  setBurger: 'SET_HEADER_BURGER_STATE',
  setBack: 'SET_HEADER_BACK_STATE',
  setSignOut: 'SET_HEADER_SIGNOUT_STATE'
}

export const setHeaderBurger = state => ({ type: actions.setBurger, state })
export const setHeaderBack = state => ({ type: actions.setBack, state })
export const setHeaderSignOut = state => ({ type: actions.setSignOut, state })

export default actions
