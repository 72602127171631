import { connect } from 'react-redux'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setRememberMe } from '../../store/remember/actions'
import UsernamePassword from './UsernamePassword'

const mapStateToProps = state => ({
  contractor: state.contractor.registeredContractor,
  rememberMe: state.rememberMe.keepLoggedIn
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setRememberMe: (state) => {
    dispatch(setRememberMe(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UsernamePassword)
