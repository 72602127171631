import api from './api'
import { sendMessage } from './appHelper'
import { store } from '../store/index'
import { setVisit } from '../store/visit/actions'
import { setSupplier, setSupplierName} from '../store/supplier/actions'

function getCurrentLocation(callback) {
  if (window.navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(
      // Success
      (position) => {
        callback({ lat: position.coords.latitude, lon: position.coords.longitude })
      },
      // Error
      () => { callback({ lat: 0, lon: 0 }) })
  } else {
    throw new Error('Your browser does not support geolocation.')
  }
}

const notSignedIn = () => ({
  isError: true, isSuccess: false, currentlySignedIn: false, passesHandedBack: true
})

const getError = error => ({
  isError: true, isSuccess: false, currentlySignedIn: true, error, passesHandedBack: true
})

const getPassesNotHandedBackError = error => ({
  isError: true, isSuccess: false, currentlySignedIn: true, error, passesHandedBack: false
})

const success = () => ({ isError: false, isSuccess: true })

const handleSignOut = async (deviceType, contractor, lat, lon, signedOutSiteType, signOutCallback) => {
  const siteName = await api.getSiteName(contractor.username)

  if (siteName === 'no visits') {
    // they are not currently signed in
    return signOutCallback(notSignedIn())
  }

  if (siteName === undefined || siteName === null) {
    return signOutCallback(getError('An error occurred signing you out'))
  }

  const details = {
    deviceType,
    latitude: lat,
    longitude: lon,
    signedOutSiteTypeId: signedOutSiteType
  }

  api.signOut(details).then((signOutResult) => {
    if (!signOutResult.isValid && signOutResult.isAllowed) {
      return signOutCallback(getError('An error occurred signing you out'))
    }

    if (!signOutResult.isAllowed && !signOutResult.isValid) {
      return signOutCallback(getPassesNotHandedBackError('Passes not handed back'))
    }

    sendMessage('signOut', Object.assign(details, { latitude: lat, longitude: lon }))

    store.dispatch(setVisit({ id: null, workOrderNumber: null }))
    
    return signOutCallback(success())
  }).catch(() => signOutCallback(getError('An error occurred signing you out')))
}

const signOut = async (deviceType, contractor, signOutSiteType, signOutCallback) => {  
  if (contractor === null) {
    return signOutCallback(getError('contractor is null'))
  }

  if (deviceType === null) {
    return signOutCallback(getError('deviceType is null'))
  }

  getCurrentLocation(async (loc) => { await handleSignOut(deviceType, contractor, loc.lat, loc.lon, signOutSiteType, signOutCallback) })
    store.dispatch(setSupplierName(null))
    store.dispatch(setSupplier({}))
}

export default signOut
