import { connect } from 'react-redux'
import SupplierSelection from './SupplierSelection'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setSupplier, setSupplierName} from '../../store/supplier/actions'
import { setTaskTime } from '../../store/taskTime/actions'
import { setSiteTask, getTask } from '../../store/task/actions'

const mapStateToProps = state => ({
  userName: state.user.userName,
  contractorId: state.loggedInUser.user.id,
  deviceType: state.device.deviceType,
  site: state.sainsburysSites.site,
  sites: state.sainsburysSites.sites,
  employerId: state.loggedInUser.user.employerId,
  signInState: state.signIn,
  supplier:state.supplier.registeredSupplier,
  supplierName:state.supplier.registeredSupplierName,
  taskTime:state.taskTime.taskTimeSelected,
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setSupplier:(supplier) =>{
      dispatch(setSupplier(supplier))
  },
  setSupplierName:(supplierName) =>{
    dispatch(setSupplierName(supplierName))
  },
  setSiteTask: (task) => {
    dispatch(setSiteTask(task))
  },
  setTaskTime: (taskTime) => {
    dispatch(setTaskTime(taskTime))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSelection)
