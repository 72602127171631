import { connect } from 'react-redux'
import Question from './Question'
import { setHeaderBurger, setHeaderSignOut } from '../../../../store/header/actions'
import { setSelectedPasses } from '../../../../store/visit/actions'

const mapStateToProps = state => ({
  selectedWorkOrder: state.visit.openVisit.workOrderNumber,
  visitId: state.visit.openVisit.id,
  visitPasses: state.visit.openVisit.visitPasses,
  selectedPass: state.visit.openVisit.visitPass,
  workOrderCompanyName: state.visit.openVisit.workOrderCompanyName,
  site: state.sainsburysSites.site
})

const mapDispatchToProps = dispatch => ({
  setHeaderBurger: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setSelectedPasses: (state) => {
    dispatch(setSelectedPasses(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Question)
