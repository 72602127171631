import { connect } from 'react-redux'
import FirePolicy from './FirePolicy'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { requestSignIn, resetSignIn, setRAMSDecline } from '../../store/signIn/actions'

const mapStateToProps = state => ({
  contractor: state.contractor.registeredContractor,
  userName: state.user.userName,
  contractorId: state.loggedInUser.user.id,
  deviceType: state.device.deviceType,
  site: state.sainsburysSites.site,
  sites: state.sainsburysSites.sites,
  employerId: state.loggedInUser.user.employerId,
  signInState: state.signIn
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  requestSignIn: (latitude, longitude) => {
    dispatch(requestSignIn(latitude, longitude))
  },
  resetSignIn: () => {
    dispatch(resetSignIn())
  },
  setRAMSDecline: (ramsdecline) => {
    dispatch(setRAMSDecline(ramsdecline))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FirePolicy)
