import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { ToggleButton } from '@jsluna/react'

class NumberSquare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedNumber: 0
    }
  }

  clickEvent = (number) => {
    const { clickEvent } = this.props
    this.setState({ selectedNumber: number })
    clickEvent(number)
  }

  render() {
    const { number } = this.props

    const { selectedNumber } = this.state

    const items = []

    for (let i = number; i > 0; i--) {
      if (i % 4 === 0) {
        items.splice(1, 0, <div className="c-number-square-line-break"><br /></div>)
      }
      items.splice(1, 0,
        <div>
          <ToggleButton
            className="ln-u-margin ln-u-padding-sides ln-u-padding-ends*2 c-number-square-size"
            onClick={() => this.clickEvent(i)}
            active={selectedNumber === i}
            alpha={selectedNumber === i}
          >
            <p className="c-number-square-text c-number-square-font">{i}</p>
          </ToggleButton>
        </div>)
    }
    return (<div className="ln-u-padding-top*2 c-number-square-container ln-u-text-align-center">{items}</div>)
  }
}

export default NumberSquare

NumberSquare.propTypes = {
  number: PropTypes.number,
  clickEvent: PropTypes.func.isRequired,
}

NumberSquare.defaultProps = {
  number: 1
}
