import { connect } from 'react-redux'
import TaskTimeSelection from './TaskTimeSelection'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setTaskTime } from '../../store/taskTime/actions'


const mapStateToProps = state => ({
    userName: state.user.userName,
    contractorId:state.loggedInUser.user.id,
    deviceType: state.device.deviceType,
    site: state.sainsburysSites.site,
    sites: state.sainsburysSites.sites,
    employerId:  state.loggedInUser.user.employerId,
    signInState: state.signIn,
    task:state.task,
    visitId: state.visit.openVisit.id,
    signedInSite: state.sainsburysSites.site,
    supplier:state.supplier.registeredSupplier,
    taskTime:state.taskTime.taskTimeSelected,
    signedInSiteType: state.sainsburysSites.site.siteType
  })

  const mapDispatchToProps = dispatch => ({
    setHeader: (state) => {
      dispatch(setHeaderBack(state))
    },
    setSignOutHeader: (state) => {
      dispatch(setHeaderSignOut(state))
    },
    setTaskTime: (taskTime) => {
      dispatch(setTaskTime(taskTime))
    }
  })

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TaskTimeSelection)
  


