import actions from './actions'

const contractor = (state = { registeredContractor: null }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, registeredContractor: action.registeredContractor }
    default:
      return state
  }
}

export default contractor
