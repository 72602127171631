import { connect } from 'react-redux'
import ForgottenPassword from './ForgottenPassword'
import { setHeaderBack } from '../../../store/header/actions'

const mapDispatchToProps = dispatch => ({
  setHeaderBack: (state) => {
    dispatch(setHeaderBack(state))
  },
})

export default connect(null, mapDispatchToProps)(ForgottenPassword)
