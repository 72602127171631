import { takeLatest } from 'redux-saga'

import signIn from './signIn/saga'
import signInActions from './signIn/actions'

function* sagas() {
  yield takeLatest(signInActions.signInRequest, signIn)
}
  
export default sagas
