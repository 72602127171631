import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Card,
  FilledButton,
  OutlinedButton,
  List,
  ListItem
} from '@jsluna/react'
import api from '../../utils/api'
import LoadingOverlay from '../LoadingOverlay'
import logger from '../../utils/logger'
import TitleComponent from '../PageTitle'

class assessmentStatements extends React.Component {
  constructor() {
    super()

    this.state = {
      statements: [],
      loading: true
    }
  }

  componentDidMount() {
    const { setHeader, setSignOutHeader, siteType } = this.props

    setHeader()
    setSignOutHeader(true)

    api.getAssessmentStatements(siteType)
      .then((res) => {
        const sortedArray = res.sort((a, b) => a.order - b.order)
        this.setState({ statements: sortedArray, loading: false })
      })
      .catch(err => logger.logError(err))
  }

  rejectClick = () => {
    const { history } = this.props

    this.setState({ loading: true })

    history.goBack()
  }

  acceptClick = () => {
    const { history, visit, selectedWorkOrder } = this.props

    this.setState({ loading: true })

    api.addWorkOrder(visit.id, selectedWorkOrder).then((result) => {
      if (result) {
        history.push('/passes')
      }
    })
  }

  render() {
    const { selectedWorkOrder } = this.props

    const { statements, loading } = this.state

    return (
      <div>
        <TitleComponent workOrderNumber={selectedWorkOrder} text="Please confirm the following statements" />
        <Card hard>
          <div className="ln-u-padding-ends*2 ln-u-padding-sides*2">
            <div className="ln-u-padding-top">
              <List>
                {
                  statements.map(ss => (
                    <ListItem key={ss.order}>
                      <p>{ss.statement}</p>
                    </ListItem>
                  ))
                }
              </List>
            </div>
            <div className="ln-u-padding-top ln-u-padding-bottom">
              <FilledButton fullWidth onClick={this.acceptClick}> I confirm</FilledButton>
            </div>
            <div className="ln-u-padding-top">
              <OutlinedButton fullWidth onClick={this.rejectClick}>{'I don\'t confirm'}</OutlinedButton>
            </div>
          </div>
        </Card>
        {loading
          && <LoadingOverlay hasBackground />
        }
      </div>
    )
  }
}

assessmentStatements.propTypes = {
  selectedWorkOrder: PropTypes.string.isRequired,
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  visit: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  siteType: PropTypes.string
}

assessmentStatements.defaultProps = {
  siteType: null
}

export default assessmentStatements
