import React from 'react'

import { Container, Card } from '@jsluna/react'

import RedErrorMessage from '../RedErrorMessage'

import SainsburysDSIBHeader from '../SainsburysDSIBHeader'

const TokenExpired = () => {
  return (
    <div className="ln-u-bg-color-white ln-o-page">
      <SainsburysDSIBHeader />
      <Card>
        <RedErrorMessage text="Email link has expired" />
        <Container className="ln-u-margin-top*2">
          <p className="ln-u-margin-bottom*2">If you still want to change your password you must request this again via the Digital sign-in book app. Just select the &apos;Forgotten password&apos; link on the log in page and follow the instructions.</p>
        </Container>
      </Card>
    </div>
  )
}

export default TokenExpired
