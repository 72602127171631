import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import {
  Container,
  FieldInfo
} from '@jsluna/react'
import SiteButton from './SiteButton/SiteButton'

class MultipleSites extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gpsError: false,
      gpsErrorText: ''
    }
  }

  componentDidMount() {
    const { setHeader, setSignOutHeader, resetSignIn } = this.props
    setHeader()
    setSignOutHeader(false)
    resetSignIn()
    this.setLocation()
  }

  setLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError)
    }
  }

  locationSuccess = () => null

  locationError = (error) => {
    const { message } = error
    let msg = message

    if(error.code === 1){
      msg = 'You need to turn on location services to find your nearest site and sign in'
    }

    if (error.code === 2) {
      msg = 'GPS is currently unavailable'
    }

    this.setState({ gpsError: true, gpsErrorText: msg })
  }

  checkSites = () => {
    const { sites } = this.props
    const { gpsError, gpsErrorText } = this.state

    if (gpsError) {
      return this.showError(gpsErrorText)
    } if (sites === null || sites === undefined || sites.length === 0) {
      return this.showError('You are not close to any sites')
    }

    return null
  }

  showError = errorMessage => (
    <div className="ln-u-body-2-fixed">
      <FieldInfo error>{errorMessage}</FieldInfo>
    </div>
  )

  handleSiteSelection = (site) => {
    const { setChosenSite, history } = this.props
    setChosenSite(site)
    history.push('/signin')
  }

  populateButtons = () => {
    const { sites } = this.props
    const { gpsError } = this.state

    if (!gpsError && sites && !sites.isEmpty) {
      return (
        <div className="ln-u-push">
          {
            sites.map(ss => <SiteButton key={ss.code} site={ss} onClick={site => this.handleSiteSelection(site)} />)
        }
        </div>
      )
    }
  }

  render() {
    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-push-top">
        <h2>Pick a site</h2>
        <Container size="xs">
          {this.checkSites()}
          <div>
            {this.populateButtons()}
          </div>
        </Container>
      </Container>
    )
  }
}

MultipleSites.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setHeader: PropTypes.func.isRequired,
  resetSignIn: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setChosenSite: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    siteType: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired
  }))
}

MultipleSites.defaultProps = {
  sites: null
}

export default MultipleSites
