import actions from './actions'

const sainsburysSites = (state = { site: null }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, site: action.site }
    case actions.setNearestSites:
      return { ...state, sites: action.sites }
    default:
      return state
  }
}

export default sainsburysSites
