const sendMessage = (type, message) => {
  if (window.ReactNativeWebView) {
    let messageJson = message

    if (message instanceof Object) {
      messageJson = JSON.stringify(message)
    }

    const toSerialize = { type, message: messageJson }
    const serializedMessage = JSON.stringify(toSerialize)
    window.ReactNativeWebView.postMessage(serializedMessage)
  }
}

const setAppRestartCallback = (callBack) => {
  if (window.ReactNativeWebView) {
    window.appRestartCallback = callBack
  }
}

export { sendMessage, setAppRestartCallback }
