import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Modal,
  Display2,
  Body1,
  FilledButton
} from '@jsluna/react'

import {
  Alert,
  AlertIcon
} from '@jsluna/alert'
import '@jsluna/alert/css/main.css'

import TickInCircleLarge from '../../../assets/TickInCircleLARGE.svg'
import CrossInCircleLarge from '../../../assets/CrossInCircleLARGE.svg'

class EmailConfirmationModal extends React.Component {
  handleButtonClick = () => {
    const { history, closeModal, type, errorModal } = this.props
    if (type === 'unauthorised' || errorModal) {
      closeModal()
    } else {
      history.push('/login')
    }
  }

  showSuccessMessage = () => {
    const { type } = this.props
    return (
      <Alert variant="success" className="ln-u-padding-ends*2" >
        <AlertIcon className="ln-u-padding-sides">
          <img src={TickInCircleLarge} alt="tick" style={{ paddingTop: '0.15rem' }} />
        </AlertIcon>
        <Display2 className="ln-u-flush-right">{type === '' ? 'Registration successful' : 'Email Sent'}</Display2>
      </Alert>
    )
  }

  showFailureMessage = () => (
    <Alert variant="error" className="ln-u-padding-ends*2">
      <AlertIcon className="ln-u-padding-sides">
        <img src={CrossInCircleLarge} alt="cross" style={{ paddingTop: '0.15rem' }} />
      </AlertIcon>
      <Display2 className="ln-u-flush-right">Verify your email address</Display2>
    </Alert>
  )

  censorFirstWord = (str) => {
    if (str === null || str === '') {
      return null
    }
    else if (str.length === 1) {
      return '*'
    }
    return str[0] + '*'.repeat(str.length - 1);
  }

  censorSecondWord = (str) => {
    if (str === null || str === '') {
      return null
    }
    else if (str.length < 3) {
      return '*'.repeat(str.length)
    }
    else if (!str.includes('.')) {
      return `${str[0] + '*'.repeat(str.length - 2) + str.slice(-1)}`
    }
    const val = str.split('.')
    if (val.length === 2) {
      return `${str[0] + '*'.repeat(val[0].length - 2) + val[0].slice(-1)}.${val[1]}`
    }
    return `${str[0] + '*'.repeat(val[0].length - 2) + val[0].slice(-1)}.${val[1]}.${val[2]}`
  }

  censorEmail = (email) => {
    if (email === null || email === '') {
      return ''
    }
    else if (!email.includes('@')) {
      return this.censorFirstWord(email)
    }
    const arr = email.split('@')
    return `${this.censorFirstWord(arr[0])}@${this.censorSecondWord(arr[1])}`
  }


  displayText = () => {
    const { email, type } = this.props
    if (type === 'emailConfirmed') {
      const emailaddress = this.censorEmail(email)
      return (
        <Body1> 
          We will send you an email to allow you to reset your password if you are a valid user (
          <strong>{emailaddress}</strong>
          ). Please ensure you verify your email before resetting the password.
        </Body1>
      )
    }
    if (type === 'unauthorised') {
      return (
        <div>
          <Body1>
            You must verify your email address before you can reset your password.
          </Body1>
          <div className="ln-u-soft-top">
            <Body1>
              We&#39;ve just sent you an email (
              <strong>{email}</strong>
              ) that will allow you to verify your email address.
            </Body1>
          </div>
        </div>
      )
    }
    return (
      <Body1>
        We&#39;ve sent you an email to verify your email address (
        <strong>{email}</strong>
        ).
        You’ll need to follow the instructions in the email before you can log in.
      </Body1>
    )
  }

  render() {
    const { isModalOpen, errorModal, type } = this.props
    return (
      <Modal fullScreen open={isModalOpen} restrictClose>
        {errorModal ? this.showFailureMessage() : this.showSuccessMessage()}
        <div className="ln-u-soft-top">
          {this.displayText()}
        </div>
        <div className="ln-u-soft-top ln-u-padding-bottom*2">
          <Body1>
            If you can’t find the email check your junk folder. Emails can sometimes take a while to arrive.
          </Body1>
        </div>
        <div className="ln-u-margin-top*4">
          <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" onClick={this.handleButtonClick}>{type === 'unauthorised' ? 'Go to reset password' : 'Go to log in'}</FilledButton>
        </div>
      </Modal>
    )
  }
}

EmailConfirmationModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  errorModal: PropTypes.bool,
  type: PropTypes.string,
  closeModal: PropTypes.func,
}

EmailConfirmationModal.defaultProps = {
  errorModal: false,
  type: '',
  closeModal: null
}

export default EmailConfirmationModal
