import React from 'react'
import { Container, Display2, Display4 } from '@jsluna/react'
import { PropTypes } from 'prop-types'


const TitleComponent = (props) => {
  const { workOrderNumber, text } = props
  return (
    <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom*3 ln-u-padding-top">
      <div className="ln-u-margin-top*4 ln-u-margin-bottom*3">
        <Display2 className="ln-u-hard-top">{`Work order: ${workOrderNumber}`}</Display2>
        <div className="ln-u-padding-top*2">
          <Display4>{text}</Display4>
        </div>
      </div>
    </Container>
  )
}

TitleComponent.propTypes = {
  text: PropTypes.string.isRequired,
  workOrderNumber: PropTypes.string.isRequired
}

export default TitleComponent
