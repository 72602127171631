import React from 'react'
import {
  Modal, ButtonGroupWrapper, ButtonGroupPrimary, FilledButton, OutlinedButton, ModalHeading
} from '@jsluna/react'
import { PropTypes } from 'prop-types'

const deletePassModal = (props) => {
  const {
    open,
    rejectClicked,
    acceptClicked,
    pass
  } = props

  return (
    <Modal fullScreen alert handleClose={rejectClicked} open={open}>
      <div className="ln-u-padding-top*3">
        <ModalHeading element="h3">Delete pass</ModalHeading>
        <p>{`Are you sure you want to delete the ${pass.name} pass?`}</p>
        <ButtonGroupWrapper actionBar>
          <ButtonGroupPrimary>
            <FilledButton onClick={() => acceptClicked(pass)}>Yes, delete this pass</FilledButton>
            <OutlinedButton onClick={rejectClicked}>No, keep this pass</OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </div>
    </Modal>
  )
}

deletePassModal.propTypes = {
  open: PropTypes.bool.isRequired,
  rejectClicked: PropTypes.func.isRequired,
  acceptClicked: PropTypes.func.isRequired,
  pass: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default deletePassModal
