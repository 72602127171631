import React from 'react'
import { Card, Display4 } from '@jsluna/react'
import { Sainsburys, withLabel } from '@jsluna/images'

const SainsburysLogo = withLabel(Sainsburys)

const SainsburysDSIBHeader = () => (
  <Card hard>
    <div className="ln-u-text-align-center ln-u-padding-top*2 ln-u-padding-bottom*2">
      <SainsburysLogo label="Sainsburys Group plc" width="200px" />
      <div className="ln-u-bg-color-orange ln-u-margin-top ln-u-padding-top*3 ln-u-padding-bottom*3">
        <Display4 className="ln-u-color-white">Digital sign-in book</Display4>
      </div>
    </div>
  </Card>

)

export default SainsburysDSIBHeader
