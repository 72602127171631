import { connect } from 'react-redux'
import HandBackPassModal from './HandBackPassModal'

const mapStateToProps = state => ({
  visitId: state.visit.openVisit.id,
  workOrderNumber: state.visit.openVisit.workOrderNumber,
  hasFireWatchHandbackQuestions: state.visit.openVisit.hasFireWatchHandbackQuestions
})

export default connect(mapStateToProps, null)(HandBackPassModal)
