import actions from './actions'

const header = (state = { burger: true, back: false, signOut: false }, action) => {
  switch (action.type) {
    case actions.setBurger:
      return {
        ...state,
        burger: true,
        back: false
      }
    case actions.setBack:
      return {
        ...state,
        burger: false,
        back: true
      }
    case actions.setSignOut:
      return {
        ...state,
        signOut: action.state,
      }
    default:
      return state
  }
}

export default header
