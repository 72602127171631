import actions from './actions'
import api from '../../utils/api'

const user = (state = { user: { fullName: null, username: null, id: null } }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, user: action.user}
    case actions.fetch:
      api.getUserDetails()
      return { ...state }
    default:
      return state
  }
}

export default user
