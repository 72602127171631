import { connect } from 'react-redux'
import ChangePassword from './ChangePassword'
import { setUser } from '../../store/loggedInUser/actions'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'

const mapStateToProps = state => ({
  user: state.loggedInUser.user,
})

const mapDispatchToProps = (dispatch) => ({
  setLoggedInUser: (state) => {
    dispatch(setUser(state))
  },
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)