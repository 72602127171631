import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Question from './Question'
import LoadingOverlay from '../../LoadingOverlay'

class PassQuestionCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      translateValue: 0,
      loading: true,
      showText: false
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  questionCallback = (loading, showText) => {
    this.setState({ loading, showText })
  }

  questionError = () => {
    const { onError } = this.props

    onError()
  }

  displayQuestions = () => {
    const { questions, history } = this.props

    if (questions) {
      return <Question data={questions} history={history} callBack={this.questionCallback} onError={this.questionError}/>
    }
    return []
  }

  render() {
    const { translateValue, loading, showText } = this.state
    return (
      <div className="c-carousel-slider">
        <div
          className="c-carousel-slider-wrapper"
          // inline style needed as cant pass props to scss files
          style={{
            transform: `translateX(${translateValue}px)`,
            transition: 'transform ease-out 0.45s',
          }}
        >
          {
            this.displayQuestions()
          }
        </div>
        {loading
          && <LoadingOverlay hasBackground text={showText ? 'Saving pass...' : ''} />
        }
      </div>
    )
  }
}

export default PassQuestionCarousel

PassQuestionCarousel.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  questions: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired
}
