import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Modal,
  ModalHeading,
  RadioButtonField,
  TextArea,
  FilledButton,
  TextInput

} from '@jsluna/react'
import logger from '../../../utils/logger'
import api from '../../../utils/api'

class HandBackPassModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reason: null,
      isJobCompleted: null,
      isFireWatchCompleted: null,
      isReasonCompleted: null,
      fireWatchCompletedBy: null,
      isFireWatchNameCompleted: null
    }
  }

  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value.trim() })
    if (e.target.value === '') {
      this.setState({ isReasonCompleted: false })
    } else {
      this.setState({ isReasonCompleted: true })
    }
  }

  handleFireWatchNameChange = (e) => {
    this.setState({ fireWatchCompletedBy: e.target.value.trim() })
    if (e.target.value === '' || e.target.value.length < 3) {
      this.setState({ isFireWatchNameCompleted: false })
    } else {
      this.setState({ isFireWatchNameCompleted: true })
    }
  }

  handleRadioButtonChange = (e) => {
    const radioButtonName = e.target.name
    const selection = parseInt(e.target.value, 10)
    if (radioButtonName === 'rbJobCompletion') {
      this.setState({ isJobCompleted: selection, isReasonCompleted: false })
    }
    if (radioButtonName === 'rbFireWatchCompletion') {
      this.setState({ isFireWatchCompleted: selection, isFireWatchNameCompleted: false })
    }
  }

  enableButtonCheck = () => {
    const {
      isJobCompleted,
      isFireWatchCompleted,
      isReasonCompleted,
      isFireWatchNameCompleted
    } = this.state

    const { hasFireWatchHandbackQuestions } = this.props

    if (isJobCompleted && isFireWatchCompleted === null && !hasFireWatchHandbackQuestions) {
      return false
    }
    if (!isJobCompleted && isReasonCompleted && isFireWatchCompleted === null && !hasFireWatchHandbackQuestions) {
      return false
    }
    if (isJobCompleted && isFireWatchCompleted) {
      return false
    }
    if (isJobCompleted && !isFireWatchCompleted && isFireWatchNameCompleted) {
      return false
    }
    if (!isJobCompleted && isFireWatchCompleted && isReasonCompleted) {
      return false
    }
    if (!isJobCompleted && !isFireWatchCompleted && isReasonCompleted && isFireWatchNameCompleted) {
      return false
    }
    return true
  }

  submitHandBackPass = () => {
    const { visitId, workOrderNumber, closeHandBackModal, loadingCallback } = this.props
    const { reason, isJobCompleted, isFireWatchCompleted, fireWatchCompletedBy } = this.state

    const handBackPass = {
      visitId,
      workOrderNumber,
      isJobCompleted,
      reason: reason !== null && reason.length > 0 ? reason : null,
      autoHandBack: false,
      isFireWatchCompleted,
      fireWatchCompletedBy
    }

    loadingCallback(true)

    api.submitHandBackPasses(handBackPass)
      .then((response) => {
        if (response === 1) {
          closeHandBackModal()
        }
        loadingCallback(false)
      }).catch((e) => { logger.logError(`An error occured handing back passes: ${e}`) })
  }

  clearStateAndHandleClose = () => {
    const { handleClose } = this.props

    this.setState(
      {
        reason: '',
        isJobCompleted: null,
        isFireWatchCompleted: null,
        isReasonCompleted: null,
        fireWatchCompletedBy: null,
        isFireWatchNameCompleted: null
      }
    )

    handleClose()
  }

  render() {
    const {
      open,
      title,
      isPlural,
      hasFireWatchHandbackQuestions
    } = this.props
    const { isJobCompleted, isFireWatchCompleted } = this.state
    return (
      <Modal fullScreen alert open={open} handleClose={this.clearStateAndHandleClose}>
        <div className="ln-u-padding-top*3">
          <ModalHeading element="h3">
            {`Hand back ${title}`}
          </ModalHeading>
          <p>{`Please select an option below before handing back ${isPlural ? 'these' : 'this'} ${title}:`}</p>
          <div>
            <RadioButtonField
              name="rbJobCompletion"
              onChange={e => this.handleRadioButtonChange(e)}
              options={[
                { value: 1, label: 'Job completed' },
                { value: 0, label: 'Job not yet completed' }
              ]}
            />
          </div>
          {isJobCompleted !== null && isJobCompleted === 0
            ? (
              <div className="ln-u-padding-left*4">
                <div className="ln-u-padding-left*3">
                  <p>Please enter your reason</p>
                  <TextArea className="ln-u-margin-bottom*3" maxLength={250} onChange={e => this.handleReasonChange(e)} />
                </div>
              </div>
            ) : ''
          }
          {hasFireWatchHandbackQuestions
            && (
              <div>
                <p>Have you completed the required fire watches?</p>
                <RadioButtonField
                  name="rbFireWatchCompletion"
                  onChange={e => this.handleRadioButtonChange(e)}
                  options={[
                    { value: 1, label: 'Yes, I completed them' },
                    { value: 0, label: 'No, someone else completed them' }
                  ]}
                />
                {isFireWatchCompleted !== null && isFireWatchCompleted === 0
                  && (
                    <div className="ln-u-padding-left*4">
                      <div className="ln-u-padding-left*3">
                        <p> Full name of person that did fire watch</p>
                        <TextInput className="ln-u-margin-bottom*2" onChange={e => this.handleFireWatchNameChange(e)} />
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
          <div className={isJobCompleted === null || isJobCompleted === 0 ? 'ln-u-soft-top' : ''}>
            <FilledButton fullWidth onClick={this.submitHandBackPass} disabled={this.enableButtonCheck()}>{`Hand back ${title}`}</FilledButton>
          </div>
        </div>
      </Modal>
    )
  }
}

HandBackPassModal.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loadingCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  visitId: PropTypes.number.isRequired,
  isPlural: PropTypes.bool.isRequired,
  workOrderNumber: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  closeHandBackModal: PropTypes.func.isRequired,
  visitPasses: PropTypes.shape({
    passTypeId: PropTypes.number.isRequired
  }).isRequired,
  hasFireWatchHandbackQuestions: PropTypes.bool.isRequired
}

export default HandBackPassModal
