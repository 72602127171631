import { makeUserManager } from 'react-oidc'
import apiBaseUrl from './apiConfig'

const identityConfig = {
  authority: apiBaseUrl,
  client_id: 'SignInBookClient',
  redirect_uri: window.location.origin + "/SignInCallback",
  response_type: 'token id_token',
  scope: 'openid profile api',
  post_logout_redirect_uri: window.location.origin + "/LoggedOut"
}

const userManager = makeUserManager(identityConfig)

export default userManager
