import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
  List,
  ListItem,
  FilledButton,
  FormGroup,
  PasswordField
} from '@jsluna/react'

import LoadingOverlay from '../LoadingOverlay'
import api from '../../utils/api'
import errorIcon from '../../assets/error.png'
import { setRememberMe, removeToken } from '../../utils/storage'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordFieldFocus: false,
      newPasswordFieldBlured: false,
      confirmPasswordBlured: false,
      loading: false,
      oldPasswordFieldError: false
    }
  }

  checkAllFieldsValid = () => {
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol
    const { newPassword, confirmPassword, oldPassword } = this.state

    if (newPassword === ''
      || confirmPassword === ''
      || oldPassword === ''
      || (newPassword !== confirmPassword)
      || (newPassword === oldPassword)
      || (regex.test(newPassword))
    ) {
      return false
    }
    return true
  }

  setOldPasswordFocus = () => {
    this.setState({ oldPasswordFieldError: false })
  }

  setNewPasswordBlur = () => {
    this.setState({ newPasswordFieldBlured: true })
  }

  setConfirmPasswordBlur = () => {
    this.setState({ confirmPasswordBlured: true })
  }

  newPasswordFieldError = () => {
    const { newPassword, newPasswordFieldBlured, oldPassword } = this.state
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol

    if (regex.test(newPassword) && newPasswordFieldBlured) {
      return "Password doesn't match the required format, please choose a different password"
    }
    if (newPassword === oldPassword && newPassword !== '' && newPasswordFieldBlured) {
      return "New password can not be same as old password, please choose a different password"
    }
    return ''
  }

  confirmPasswordFieldError = () => {
    const { newPassword, confirmPassword, confirmPasswordBlured } = this.state

    if (confirmPassword !== newPassword && confirmPasswordBlured) {
      return "New password doesn't match, please enter the same password to confirm"
    }
    return ''
  }

  handleTextChange = (e, key) => {
    const state = {}
    const { value } = e.target

    state[key] = value
    this.setState(state)
  }

  handleResetPassword = () => {
    const { history, setLoggedInUser } = this.props
    const { oldPassword, newPassword } = this.state

    this.setState({ loading: true })

    const key = 'oidc.user:' + `${window.location.origin}` + '/api/:SignInBookClient'
    const userId = JSON.parse(sessionStorage.getItem(key)).profile.sub

    api.validateAndResetPassword(userId, oldPassword, newPassword)
      .then(res => {
        if (res === 200) {
          removeToken()
          setRememberMe(false)
          setLoggedInUser(null)
          sessionStorage.clear()
          document.cookie = "SignInBookSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
          document.cookie = "idsrv.session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
          history.push(`/resetsuccess`)
        }
        else if (res === 401) {
          this.setState({ oldPasswordFieldError: true, loading: false })
          return
        }
        else {
          this.setState({ loading: false })
          history.push(`/reseterror`)
          return
        }
      })
  }

  render() {
    const { setHeader, setSignOutHeader } = this.props
    setHeader()
    setSignOutHeader(false)
    const { oldPassword, newPassword, confirmPassword, loading } = this.state
    const newPasswordErrorText = this.newPasswordFieldError()
    const confirmPasswordErrorText = this.confirmPasswordFieldError()
    let errorText = ''
    if (newPasswordErrorText !== '') {
      errorText = newPasswordErrorText
    }
    else if (confirmPasswordErrorText !== '') {
      errorText = confirmPasswordErrorText
    }

    return (
      <div className="centered-content ln-u-margin-top*2">
        <h3>Change Password</h3>
        <Container className="ln-u-margin-top">
          <div className="ln-c-form-group">
            <FormGroup
              name="txtOldPassword"
              label="Old password"
              className="form-spacing"
            >
              <PasswordField
                name="txtOldPassword"
                className={this.state.oldPasswordFieldError === true ? 'ln-c-form-group has-error' : ''}
                value={oldPassword}
                onFocus={() => this.setOldPasswordFocus()}
                onChange={e => this.handleTextChange(e, 'oldPassword')}
              />
            </FormGroup>
            {this.state.oldPasswordFieldError === true
              && (
                <div className="form-error-div">
                  <div style={{ marginRight: '10px' }}>
                    <img src={errorIcon} />
                  </div>
                  <FormGroup
                    name="txtOldPasswordError"
                    error="Please enter correct password"
                    style={{ marginBottom: '0rem' }}
                  >
                  </FormGroup>
                </div>
              )
            }
          </div>
          <div className="ln-c-form-group">
            <FormGroup
              name="txtNewPassword"
              label="New password"
            >
              <p>Please enter a new password in the following format:</p>
              <List className='list-identation'>
                <ListItem>at least 1 uppercase letter</ListItem>
                <ListItem>at least 1 number</ListItem>
                <ListItem>at least 1 special character (!*.-?)</ListItem>
                <ListItem>at least 8 characters long</ListItem>
              </List>
              <PasswordField
                name="txtNewPassword"
                className={newPasswordErrorText !== '' ? 'ln-c-form-group has-error' : ''}
                value={newPassword}
                onBlur={() => this.setNewPasswordBlur()}
                onChange={e => this.handleTextChange(e, 'newPassword')}
              />
            </FormGroup>
          </div>
          <div className="ln-c-form-group">
            <FormGroup
              name="txtConfirmNewPassword"
              label="Confirm new password"
              className="form-spacing"
            >
              <PasswordField
                name="txtConfirmNewPassword"
                className={confirmPasswordErrorText !== '' ? 'ln-c-form-group has-error' : ''}
                value={confirmPassword}
                onBlur={() => this.setConfirmPasswordBlur()}
                onChange={e => this.handleTextChange(e, 'confirmPassword')}
              />
            </FormGroup>
            {errorText !== ''
              && (
                <div className="form-error-div">
                  <div style={{ marginRight: '10px' }}>
                    <img src={errorIcon} />
                  </div>
                  <FormGroup
                    name="txtConfirmNewPasswordError"
                    error={errorText}
                    style={{ marginBottom: '0rem' }}
                  >
                  </FormGroup>
                </div>
              )
            }
          </div>
          <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary ln-u-1/3@xs" type="button" disabled={!this.checkAllFieldsValid()} onClick={() => this.handleResetPassword()}>Change Password</FilledButton>
        </Container>
        {loading
          && <LoadingOverlay hasBackground text="Changing your password..." />
        }
      </div>
    )
  }
}

ChangePassword.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLoggedInUser: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired
}

ChangePassword.defaultProps = {
  user: {
    username: null
  }
}

export default ChangePassword