import { connect } from 'react-redux'
import LoginCallback from './LoginCallback'
import { setUser } from '../../store/loggedInUser/actions'
import { setVisit } from '../../store/visit/actions'

const mapStateToProps = state => ({
  username: state.user.userName,
})

const mapDispatchToProps = dispatch => ({
  setUser: (user) => {
    dispatch(setUser(user))
  },
  setVisit: (visit) => {
    dispatch(setVisit(visit))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback)
