import React from 'react'
import {
  Modal, FilledButton, FieldInfo
} from '@jsluna/react'
import { PropTypes } from 'prop-types'
import Text from './TermsAndConditionsText'

const NewTCsModal = (props) => {
  const {
    isOpen, acceptClick, error, close
  } = props

  return (
    <Modal open={isOpen} fullScreen handleClose={close}>
      <h2 className="ln-u-flush-sides">Terms and conditions</h2>
      <Text />
      {error
        && (
          <FieldInfo error>{error}</FieldInfo>
        )}
      <FilledButton fullWidth onClick={acceptClick}>I agree to the T&Cs</FilledButton>
    </Modal>
  )
}

NewTCsModal.propTypes = {
  isOpen: PropTypes.bool,
  acceptClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  close: PropTypes.func
}

NewTCsModal.defaultProps = {
  isOpen: false,
  error: '',
  close: null
}

export default NewTCsModal
