import actions from './actions'

const passTypes = (state = { passTypes: [] }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, passTypes: action.passTypes}
    default:
      return state
  }
}

export default passTypes
