import React from 'react'
import { PropTypes } from 'prop-types'

import {
  Text,
  Card,
  Container,
  FilledButton
} from '@jsluna/react'
import {
  Alert,
  AlertIcon
} from '@jsluna/alert'
import '@jsluna/alert/css/main.css'

import TickInCircle from '../../assets/TickInCircle.svg'
import timerIcon from '../../assets/TimerIcon.svg'
import CrossInCircle from '../../assets/CrossInCircle.svg'
import BlackInfoIcon from '../../assets/BlackInfoIcon.svg'
import HandBackPassModal from '../Passes/HandbackPassModal'
import redirectUser from '../../utils/routeHelper'
import LoadingOverlay from '../LoadingOverlay'

class WorkOrderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pluraliseBtntext: 'pass',
      icon: <img src={timerIcon} alt="timer" />,
      wording: '',
      authorised: false,
      authorisedText: 'OK, you can start work now',
      pending: null,
      pendingText: 'Wait for manager\'s authorisation',
      rejected: false,
      rejectedText: 'Rejected - do not start work',
      handBackPassModalOpen: false,
      handedBackToastOpen: false,
      loading: false
    }
  }

  componentWillReceiveProps = () => {
    this.setPassAuthorisationStatus()
  }

  setPassAuthorisationStatus = () => {
    const { permits, status, text } = this.props
    const { authorisedText, pendingText, rejectedText } = this.state

    if (permits.props.children.length > 1) {
      this.setState({ pluraliseBtntext: 'passes' })
    }

    switch (status) {
      case 'authorised':
        this.setState({ authorised: true, wording: text || authorisedText, icon: <img src={TickInCircle} alt="tick" /> })
        break
      case 'pending':
        this.setState({ pending: 'info', wording: text || pendingText, icon: <img src={timerIcon} alt="timer" /> })
        break
      case 'rejected':
        this.setState({ rejected: true, wording: text || rejectedText, icon: <img src={CrossInCircle} alt="cross" /> })
        break
      default:
        this.setState({ rejected: true, wording: 'Status must be a string and one of authorised, pending, or rejected' })
        break
    }
  }

  openHandBackModal = () => {
    this.setState({ handBackPassModalOpen: true })
  }

  closeHandBackModal = () => {
    const { history, loadingCallback } = this.props
    this.setState({ handBackPassModalOpen: false, handedBackToastOpen: true, loading: false })
    loadingCallback(true)
    redirectUser(history)
  }

  loadingCallback = (loading) => {
    this.setState({ loading })
  }

  showHandBackPassModal = () => {
    const { handBackPassModalOpen, pluraliseBtntext } = this.state
    const { permits } = this.props

    return (
      <HandBackPassModal loadingCallback={this.loadingCallback} open={handBackPassModalOpen} handleClose={() => this.setState({ handBackPassModalOpen: false })} title={pluraliseBtntext} isPlural={permits.props.children.length > 1} closeHandBackModal={this.closeHandBackModal} />
    )
  }

  displayNoWorkOrders = () => {
    const { workOrdersLength, hasPassesHandedBack } = this.props
    return (
      <div>
        <Card>
          {hasPassesHandedBack ? this.displayHandedBackToastMessage() : ''}
          <p className="ln-u-padding-top">Please remember to sign out when you leave the site.</p>
          {workOrdersLength !== 0
            && (
              <Card className="ln-u-border ln-u-text-align-center ln-u-font-weight-medium ln-u-margin-top*3">
                <Text>No open work orders</Text>
              </Card>
            )
          }
        </Card>
        {this.displaySelectAnotherWOPrompt()}
      </div>
    )
  }

  displaySelectAnotherWOPrompt = () => {
    return (
      <Container className="ln-u-margin-top*4 ln-u-margin-sides*2 ln-u-text-align-center">
        <Text>To select another work order or pass you need to sign out and sign in again.</Text>
      </Container>
    )
  }

  displayWorkOrderStatus = () => {
    const {
      workOrderNumber,
      status,
      permits,
      hasPassesHandedBack
    } = this.props

    const {
      authorised,
      pending,
      rejected,
      icon,
      wording,
      pluraliseBtntext
    } = this.state

    var alertType = 'info'
    if (authorised) { alertType = 'success' }
    else if (rejected) { alertType = 'error' }

    return (
      (workOrderNumber && status && !hasPassesHandedBack) ? (
        <div>
          <Card className="ln-u-padding-sides*2 ln-u-padding-bottom u-background-colors-white ln-u-padding-top*2" hard>
            <h3 className="u-wrap-word ln-u-padding-top*2 ln-u-margin-bottom*3">{`Work order: ${workOrderNumber}`}</h3>
            <Alert variant={alertType}>
              <AlertIcon className='margin-top-minor'>
                {icon}
              </AlertIcon>
              <div className="ln-u-margin-top*0.5 ln-u-padding-left">
                <Text>
                  {wording}
                </Text>
              </div>
            </Alert>
            {permits}
            {(permits.props.children.length > 0 && authorised) && <FilledButton className="ln-u-margin-top*2 ln-u-margin-bottom" fullWidth onClick={this.openHandBackModal}>{`Hand back ${pluraliseBtntext}`}</FilledButton>}
          </Card>
          {this.displaySelectAnotherWOPrompt()}
        </div>
      )
        : (
          this.displayNoWorkOrders()
        )
    )
  }

  displayHandedBackToastMessage = () => {
    const {
      handedBackToastOpen,
      pluraliseBtntext
    } = this.state

    const caplitalisedPluralText = pluraliseBtntext.charAt(0).toUpperCase() + pluraliseBtntext.slice(1)

    return (
      <Alert
        className="ln-u-hard ln-u-margin-bottom"
        variant='warning'
        open={handedBackToastOpen}
        squared
        onClose={() => this.setState({ handedBackToastOpen: false })}
      >
        <AlertIcon className="ln-u-padding-left*2 ln-u-margin-top ln-u-margin-bottom">
          <img src={BlackInfoIcon} alt="information" />
        </AlertIcon>
        <div className="ln-u-padding-left ln-u-margin-top ln-u-margin-bottom">
          <Text>
            {`${caplitalisedPluralText} handed back`}
          </Text>
        </div>
      </Alert>
    )
  }

  render() {
    const { loading } = this.state
    const { permits } = this.props

    const isPlural = permits.props.children.length > 1

    return (
      <div>
        {this.showHandBackPassModal()}
        {
          this.displayWorkOrderStatus()
        }
        {loading
          && <LoadingOverlay hasBackground text={`Handing back ${isPlural ? 'passes' : 'pass'}...`} />
        }
      </div>
    )
  }
}

WorkOrderContainer.propTypes = {
  workOrderNumber: PropTypes.string,
  status: PropTypes.oneOf(['authorised', 'pending', 'rejected']),
  workOrdersLength: PropTypes.number.isRequired,
  permits: PropTypes.node,
  text: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  hasPassesHandedBack: PropTypes.bool.isRequired,
  loadingCallback: PropTypes.func.isRequired,
}

WorkOrderContainer.defaultProps = {
  workOrderNumber: '',
  status: 'authorised',
  permits: null,
  text: null,
}


export default WorkOrderContainer
