import React from 'react'
import {
  Modal,
  ModalHeading,
  Text,
  FilledButton
} from '@jsluna/react'
import {
  Alert,
  AlertIcon
} from '@jsluna/alert'
import '@jsluna/alert/css/main.css'
import BlackInfoIcon from '../../../assets/BlackInfoIcon.svg'
import { PropTypes } from 'prop-types'

const PreventSignOutModal = (props) => {
  const { isOpen, handleClose, okClick, isPlural } = props
  return (
    <Modal fullScreen alert open={isOpen} handleClose={handleClose}>
      <div className="ln-u-padding-top*3">
        <ModalHeading element="h3">
          Sign out
        </ModalHeading>
        <Alert
          className="ln-u-hard ln-u-margin-bottom"
          variant="warning"
          squared
        >
          <AlertIcon className="ln-u-padding-left*2 ln-u-margin-bottom ln-u-margin-top*1/2">
            <img src={BlackInfoIcon} alt="information" />
          </AlertIcon>
          <div>
            <Text>
              {`Your ${isPlural ? 'passes are' : 'pass is'} still open`}
            </Text>
          </div>
        </Alert>
        <div className="ln-u-padding-top*2 ln-u-padding-bottom">
          <Text>{`You need to hand back your ${isPlural ? 'passes' : 'pass'} before you sign out.`}</Text>
        </div>
        <div className="ln-u-soft-top">
          <FilledButton fullWidth onClick={okClick}>OK</FilledButton>
        </div>
      </div>
    </Modal>
  )
}

PreventSignOutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  okClick: PropTypes.func.isRequired
}

export default PreventSignOutModal
