import { connect } from 'react-redux'
import MultipleSites from './MultipleSites'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setSite } from '../../store/site/actions'
import { resetSignIn } from '../../store/signIn/actions'

const mapStateToProps = state => ({
  sites: state.sainsburysSites.sites,
  site: state.sainsburysSites.site
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setChosenSite: (site) => {
    dispatch(setSite(site))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  resetSignIn: () => {
    dispatch(resetSignIn())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSites)
