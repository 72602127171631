import React from 'react'
import { PropTypes } from 'prop-types'

import { FlagWrapper, FlagComponent, FlagBody, Display2 } from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'

const RedErrorMessage = (props) => {
  const { text } = props
  return (
    <div>
      <FlagWrapper className="ln-u-color-red">
        <FlagComponent alignment="top">
          <div className="ln-u-padding-right ln-u-padding-top">
            <ErrorCircle size="large" />
          </div>
        </FlagComponent>
        <FlagBody alignment="top">
          <div className="ln-u-padding-top">
            <Display2>{text}</Display2>
          </div>
        </FlagBody>
      </FlagWrapper>
    </div>
  )
}

RedErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
}

export default RedErrorMessage
