import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FeedbackCard from './FeedbackCard'

class Feedback extends React.Component {
  constructor(props) {
		super(props)
	}

  render() {
    const { appId, email, headerText, bodyText, showFeedback } = this.props

    if (showFeedback === true) {
      return <FeedbackCard appId={appId} email={email} headerText={headerText} bodyText={bodyText} />
    }
  
    return (<></>)
  }
}

Feedback.propTypes = {
  appId: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  showFeedback: PropTypes.bool.isRequired
}

export default Feedback