import actions from './actions'

const user = (state = { userName: null }, action) => {
  switch (action.type) {
    case actions.setName:
      return { ...state, userName: action.userName }
    default:
      return state
  }
}

export default user
