import React from 'react'
import MenuButton from './MenuButton'
import { PropTypes } from 'prop-types'

const SignInButton = props => (
  <MenuButton onClick={() => props.enabled ? props.onClick() : null } enabled={props.enabled}>
    <g fill={props.enabled ? '#f06c00' : '#8e8e8e'} fillRule="nonzero" transform="translate(55 57.5)">
      {/* eslint-disable */}
        <path d="m47.966627 55.0111447c0 2.8210535 2.283757 5.1079691 5.100914 5.1079691s5.1009139-2.2869156 5.1009139-5.1079691v-25.5619811c0-2.8210534-2.2837569-5.1079691-5.1009139-5.1079691h-25.5070604c-2.817157 0-5.1009139 2.2869157-5.1009139 5.1079691 0 2.8210535 2.2837569 5.1079692 5.1009139 5.1079692h20.4061464z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 41.668929 -16.137385)" />
        <path d="m47.3502549 20.7384259-35.7063976 35.755784c-1.99203076 1.994786-1.99203076 5.2289732 0 7.2237592 1.9920308 1.994786 5.2217509 1.994786 7.2137817 0l35.7063976-35.755784c1.9920308-1.994786 1.9920308-5.2289732 0-7.2237592-1.9920308-1.9947861-5.2217509-1.9947861-7.2137817 0z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 39.555766 -11.039673)" />
        <path d="m84.6717708 75.0966752c2.8171569 0 5.1009139 2.2837569 5.1009139 5.1009139s-2.283757 5.1009139-5.1009139 5.1009139h-56.6167441c-2.817157 0-5.100914-2.2837569-5.100914-5.1009139v-75.09565498c0-2.81715698 2.283757-5.10091394 5.100914-5.10091394h56.6167441c2.8171569 0 5.1009139 2.28375696 5.1009139 5.10091394s-2.283757 5.10091398-5.1009139 5.10091398h-51.5158302v64.8938271z" transform="matrix(-1 0 0 1 112.726798 0)" />
        {/* eslint-enable */}
    </g>
  </MenuButton>
)

SignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool
}

SignInButton.defaultProps = {
  enabled: true
}

export default SignInButton
