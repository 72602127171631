import { connect } from 'react-redux'
import ConfirmationPage from './ConfirmationPage'
import { setHeaderBurger, setHeaderSignOut } from '../../store/header/actions'

const mapStateToProps = state => ({
  user: state.loggedInUser.user
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage)
