import React from 'react'
import {
  Display2,
  FlagWrapper,
  FlagComponent,
  FlagBody
} from '@jsluna/react'
import { PropTypes } from 'prop-types'
import GreenTick from '../../../assets/GreenTick.svg'
import passIcon from '../../../assets/PassIcon.svg'
import { visitPassActionTypes } from '../../../utils/constants'
import { AlertIcon } from '@jsluna/alert'
import '@jsluna/alert/css/main.css'

const PassView = (props) => {
  const { pass } = props
  return (
    <div className="ln-u-margin-top*2 ln-u-margin-bottom ln-u-bg-color-grey-v-light">
      <div className="ln-u-padding-sides*2 ln-u-padding-top*2 ln-u-padding-bottom*2 ln-u-padding-left*2">
        <FlagWrapper>
          <FlagComponent>
            <AlertIcon>
              <div className="ln-u-padding-right ln-u-padding-top*1/2">
                <img src={passIcon} alt="pass icon" />
              </div>
            </AlertIcon>
          </FlagComponent>
          <FlagComponent>
            <Display2 className="ln-u-hard-bottom">{pass.passName}</Display2>
          </FlagComponent>
          <FlagBody className="ln-u-text-align-right ln-u-padding-top">
            {pass.actionTypeId === visitPassActionTypes.approved && <img src={GreenTick} alt="green tick" />}
          </FlagBody>
        </FlagWrapper>
      </div>
    </div>
  )
}

PassView.propTypes = {
  pass: PropTypes.shape({
    passId: PropTypes.number.isRequired,
    passName: PropTypes.string.isRequired,
    actionTypeId: PropTypes.number.isRequired
  }).isRequired
}

export default PassView
