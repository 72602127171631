import { connect } from 'react-redux'
import PassQuestions from './PassQuestions'
import { setHeaderBack, setHeaderSignOut } from '../../../store/header/actions'
import { setSelectedPasses } from '../../../store/visit/actions'

const mapStateToProps = state => ({
  selectedWorkOrder: state.visit.openVisit.workOrderNumber,
  visitId: state.visit.openVisit.id,
  selectedPass: state.visit.openVisit.visitPass
})

const mapDispatchToProps = dispatch => ({
  setHeaderBack: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setSelectedPasses: (state) => {
    dispatch(setSelectedPasses(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PassQuestions)
