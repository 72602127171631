import { connect } from 'react-redux'
import { setHeaderBack } from '../../store/header/actions'
import ErrorPage from './ErrorPage'

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  }
})

export default connect(null, mapDispatchToProps)(ErrorPage)
