import React from 'react'
import { Container, List } from '@jsluna/react'

const FireItems = [
  'The fire alarm in this store is bells and sounders',
  'If you hear a continuous alarm, evacuate the building by the nearest available exit',
  'See the signage on the wall above to show where the assembly point for roll call is located for this store',
  'If you hear an intermittent alarm make your way to a ground floor exit and standby ready to evacuate',
  'If you discover a fire, immediately operate the nearest break glass fire alarm call point',
  'Only use a fire extinguisher if the fire is small and you have been trained in the correct type and safe use of the equipment',
  'Smoking is not allowed except in the designated smoking area',
  'Contractors - Before carrying out any \'hot work\', high level / overhead work, confined space working, live high '
  + 'voltage equipment working or working with asbestos you must first obtain a permit to work from the Duty Store Manager',
  'Contractors should familiarise themselves with the Contractors Guide',
  'Please speak to any store colleague if you have any queries'
]

const FireSafetyPolicy = () => {
  return (
    <Container>
      <h3>Fire Safety Statement</h3>
      <List items={FireItems} spaced />
    </Container>
  )
}

export default FireSafetyPolicy
