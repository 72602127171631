import React from 'react'
import { slide as BurgerMenu } from 'react-burger-menu'
import { PropTypes } from 'prop-types'
import { Text, TextButton, FilledButton } from '@jsluna/react'
import cancelIcon from '../../assets/cancel.svg'
import { isTokenExpired, getRememberMe, setRememberMe } from '../../utils/storage'
import userManager from '../../utils/identity'
import { sendMessage } from '../../utils/appHelper'

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '0',
    height: '0',
    left: '0',
    top: '0',
    background: 'red'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    left: window.innerWidth > 720 ? '18px' : '12px',
    top: window.innerWidth > 720 ? '18px' : '10px'
  },
  bmCross: {
    background: 'white'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: 'white',
    overflow: 'hidden',
    width: 'fit-content',
    minWidth: '300px'
  }
}

class Menu extends React.Component {

  menuStateChanged = (state) => {
    const { toggleMenu } = this.props

    isTokenExpired()

    if (!state.isOpen) {
      toggleMenu(false)
    }
  }

  logout = () => {
    setRememberMe(false)
    sendMessage('logout', 'logout')
    userManager.signoutRedirect()
  }

  tokenExpired = () => {

    const remember = getRememberMe()

    if (remember === 'true') {
      return false
    }

    return isTokenExpired()
  }

  handleResetPassword = () => {
    this.props.toggleMenu(false)
    this.props.history.push('/changepassword')
  }

  renderLinks = () => {
    const { user } = this.props

    const tokenExpired = this.tokenExpired()

    if (!tokenExpired && user) {
      // eslint-disable-next-line
      return (
        <React.Fragment>
          <div className="ln-u-soft">
            <div className="ln-u-push-bottom u-wrap-word">
              <div>
                <b>{user.fullName}</b>
                <p>{user.username}</p>
              </div>
            </div>
                  <div className='custom-text-button' onClick={() => this.handleResetPassword()}>Change password</div>
                  <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
              </div>
              <div className="ln-u-border-bottom custom-margin-left" />
          <div className="ln-u-soft">
            <FilledButton className="logoutbutton" onClick={() => this.logout()}>Log Out</FilledButton>
          </div>
        </React.Fragment>
      )
    }
    if (tokenExpired || !user) {
      return (
        <React.Fragment>
              <div className="ln-u-push-left">
                  <div className="ln-u-soft ln-u-push-left">
                      <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                  </div>
            <div className="ln-u-soft ln-u-push-left">
              <a className="ln-u-hard ln-c-navigation__link c-header-menu-link" href="/Login">Log in</a>
                  </div>
          </div>
              <div className="ln-u-border-bottom ln-u-flush" />
        </React.Fragment>
      )
    }
  }

  render() {
    const { isOpen } = this.props
    
    return (
      <div className="ln-o-flag">
        <BurgerMenu isOpen={isOpen} styles={styles} onStateChange={this.menuStateChanged} customCrossIcon={<img src={cancelIcon} alt="close icon" />}>
          <div className="ln-u-push-left ln-u-push-top">
            <div className="ln-u-push-left">
              <Text className="c-header ln-u-push-left">Sign-in book</Text>
            </div>
                </div>
                
                {this.renderLinks()}

        </BurgerMenu>
      </div>
    )
  }
}

Menu.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string
  }),
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

Menu.defaultProps = {
  user: {
    username: null
  }
}

export default Menu
