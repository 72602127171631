import React from 'react'

import { Container, Card, Display2 } from '@jsluna/react'

import SainsburysDSIBHeader from '../SainsburysDSIBHeader'

const loginRedirect = () => {
  window.location.href = `${window.location.origin}\\login`
}

const PasswordChangedSuccess = () => {
  return (
    <div className="ln-u-bg-color-white ln-o-page">
      <SainsburysDSIBHeader />
      <Card>
        <Display2>Your password has been changed</Display2>
        <Container className="ln-u-margin-top*2">
          <p className="ln-u-margin-bottom*2">Please open the Digital sign-in book app and log in with this new password.</p>
          <p>Click <a className='custom-text-button' onClick={loginRedirect}>here</a> to access the Log in page</p>
        </Container>
      </Card>
    </div>
  )
}

export default PasswordChangedSuccess
