import { connect } from 'react-redux'
import SignOut from './SignOut'
import { setHeaderBurger, setHeaderSignOut } from '../../store/header/actions'
import { setSupplier, setSupplierName} from '../../store/supplier/actions'

const mapStateToProps = state => ({
  user: state.user,
  contractor: state.loggedInUser.user,
  deviceType: state.device.deviceType,
  signedOutSiteType: state.sainsburysSites.site.siteType,
  supplier:state.supplier.registeredSupplier,
  supplierName:state.supplier.registeredSupplierName
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setSupplier:(supplier) =>{
    dispatch(setSupplier(supplier))
},
setSupplierName:(supplierName) =>{
  dispatch(setSupplierName(supplierName))
},
})

export default connect(mapStateToProps, mapDispatchToProps)(SignOut)
