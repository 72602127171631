import React from 'react'

import { Container, Card, Link } from '@jsluna/react'

import RedErrorMessage from '../RedErrorMessage'

import SainsburysDSIBHeader from '../SainsburysDSIBHeader'

const PasswordChangedError = () => {
  
  if (window.location.search === '') {
    return (
      <div className="ln-u-bg-color-white ln-o-page">
        <SainsburysDSIBHeader />
        <Card>
          <RedErrorMessage text="Sorry, your password could not be changed at this time" />
          <Container className="ln-u-margin-top*2">
            <Link className="ln-u-margin-bottom*2" href='SignIn'>Try again later</Link>
          </Container>
        </Card>
      </div>
    )
  }
  else {
    const search = new URLSearchParams(window.location.search)
    return (
      <div className="ln-u-bg-color-white ln-o-page">
        <SainsburysDSIBHeader />
        <Card>
          <RedErrorMessage text="Sorry, your password could not be changed at this time" />
          <Container className="ln-u-margin-top*2">
            <Link className="ln-u-margin-bottom*2" href={`resetpassword?${search}`}>Try again</Link>
          </Container>
        </Card>
      </div>
    )
  }
}

export default PasswordChangedError
