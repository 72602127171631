import { connect } from 'react-redux'
import SignIn from './SignIn'
import { setHeaderBurger, setHeaderSignOut } from '../../store/header/actions'
import { setSite, setClosestSites } from '../../store/site/actions'
import { resetSignIn, requestSignIn, setSignInState, setCarRegistration } from '../../store/signIn/actions'

const mapStateToProps = state => ({
  userName: state.user.userName,
  deviceType: state.device.deviceType,
  site: state.sainsburysSites.site,
  sites: state.sainsburysSites.sites,
  signInStatus: state.signIn,
  user: state.loggedInUser.user,
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setNearbySites: (sites) => {
    dispatch(setClosestSites(sites))
  },
  setChosenSite: (site) => {
    dispatch(setSite(site))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  resetSignIn: () => {
    dispatch(resetSignIn())
  },
  requestSignIn: (latitude, longitude) => {
    dispatch(requestSignIn(latitude, longitude))
  },
  setSignInStatus: (status) => {
    dispatch(setSignInState(status))
  },
  setCarRegistrationNumber: (status) => {
    dispatch(setCarRegistration(status))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
