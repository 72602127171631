import React, { useState } from 'react'
import { Modal, ModalHeading } from '@jsluna/modal'
import PropTypes from 'prop-types'
import { Body1 } from '@jsluna/typography'
import { TextAreaField } from '@jsluna/form'
import { FilledButton, TextButton, CancelButton } from '@jsluna/button'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ErrorCircle } from '@jsluna/icons'
import ThanksMessage from './ThanksMessage'
import api from '../../utils/api'

const satisfactionLevels = [
	[1, '😰', 'nervous face', 'Very dissatisfied'],
	[2, '😩', 'veary face', 'Dissatisfied'],
	[3, '😐', 'neutral face', 'Neutral'],
	[4, '😄', 'smiley face', 'Satisfied'],
	[5, '😍', 'heart face', 'Very satisfied'],
]

class FeedbackCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			formSubmitted: false,
			isOpen: true,
			showError: false,
			disabledButton: false,
			feedback: { rating: '', comment: '' }
		}
	}

	submitForm = async () => {
		const { appId, email } = this.props
		api.submitFeedback(appId, this.state.feedback, email)
			.then(res => {
				this.setState({ formSubmitted: true })
			})
	}

	handleClose = () => {
		const { appId, email } = this.props
		api.submitFeedback(appId, { rating: 0, comment: '' }, email)
		.then(res => {
			this.setState({ isOpen: false })
		})
	}

	setRating = ratingLevel => {
		this.setState({ feedback: { ...this.state.feedback, rating: ratingLevel } })
		this.setState({ showError: false })
	}

	handleChange = input => {
		const { value } = input.target
		this.setState({ feedback: { ...this.state.feedback, comment: value } })
	}

	handleValidation = () => {
		if (this.state.feedback.rating === '') {
			this.setState({ showError: true })
		} else {
			this.setState({ disabledButton: true })
			this.submitForm()
		}
	}

	render() {
		const { formSubmitted, isOpen, showError, disabledButton } = this.state
		const { headerText, bodyText } = this.props

		if (formSubmitted) {
			return <ThanksMessage />
		}

		return (
			<Modal small alert hideCloseButton open={isOpen} headingId="dialog-modal">
				<GridItem size="9/10" className="ln-u-hard">
					<ModalHeading element="h4">
						{headerText}
					</ModalHeading>
				</GridItem>
				<GridItem size="1/10" className="ln-u-hard">
					<CancelButton onClick={this.handleClose} />
				</GridItem>
				<Body1>
					{bodyText}
				</Body1>
				<ModalHeading element="h5" className="ln-u-margin-top*2">
					How satisfied are you with this tool?
				</ModalHeading>
				<GridWrapper>
					{satisfactionLevels.map((satisfactionLevel, index) => (
						<GridItem size={{ xs: '1/5' }} className="radio-toolbar" key={`satlevel-${satisfactionLevel[0]}`}>
							<div className="feedback-satisfactionLevel">
								<input
									type="radio"
									id={index}
									name="radioFruit"
									value={satisfactionLevel[0]}
									onClick={() => this.setRating(satisfactionLevel[0])}
								/>
								<label htmlFor={index}>
									<span
										aria-label="aria-label"
										role="img"
										className="feedback-emojiImage"
									>
										{satisfactionLevel[1]}
									</span>
									<div className="feedback-emojiButtonName ln-u-padding-top">
										{satisfactionLevel[3]}
									</div>
								</label>
							</div>
						</GridItem>
					))}
				</GridWrapper>
				{showError ? (
					<div className="feedback-error ln-u-font-weight-bold ln-u-margin-top">
						<ErrorCircle className="ln-u-color-red ln-u-display-1 ln-u-margin-right" />
						Please rate the app before submitting
					</div>
				) : (
					<div />
				)}
				<ModalHeading element="h5" className="ln-u-margin-top*2">
					How could we improve it? (optional)
				</ModalHeading>
				<TextAreaField
					placeholder="Add any additional comments here ..."
					className="feedback-inputField ln-u-margin-top"
					onChange={this.handleChange}
				/>
				<FilledButton
					fullWidth
					id="submit-button"
					className="feedback-submitButton ln-u-margin-bottom*2"
					onClick={this.handleValidation}
					disabled={disabledButton}
				>
					Submit
				</FilledButton>
				<TextButton
					fullWidth
					id="maybe-later-button"
					className="feedback-closeButton"
					onClick={this.handleClose}
					disabled={disabledButton}
				>
					Maybe Later
				</TextButton>
			</Modal>
		)
	}

}

FeedbackCard.propTypes = {
	appId: PropTypes.string.isRequired,
	headerText: PropTypes.string.isRequired,
	bodyText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

export default FeedbackCard