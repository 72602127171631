import actions from './actions'

const supplier = (state = { registeredSupplier: {supplierName:null,supplierId:0,acceptedTCs:false},registeredSupplierName:null }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, registeredSupplier: action.registeredSupplier }
    case actions.setName:
      return { ...state, registeredSupplierName: action.registeredSupplierName }
    default:
      return state
  }
}

export default supplier
