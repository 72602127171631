import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  FormGroup
} from '@jsluna/react'

import SignOutButton from '../Buttons/SignOutButton'
import api from '../../utils/api'
import logger from '../../utils/logger'
import LoadingOverlay from '../LoadingOverlay'
import signOut from '../../utils/signOutHelper'

class SignOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      siteName: undefined,
      loading: false,
      signOutError: null
    }
  }

  componentDidMount() {
    const { setHeader, setSignOutHeader, history } = this.props
    setHeader()
    setSignOutHeader(false)
    this.getSiteName()
  }

  signOutStatus = () => {
    const { signOutError } = this.state
    if (signOutError) {
      return <FormGroup error={signOutError} />
    }
    return ''
  }

  getSiteName = async () => {
    const { user } = this.props
    await api.getSiteName(user.userName)
      .then((res) => {
        this.setState({
          siteName: res,
          loading: false
        })
      })
      .catch((e) => {
        logger.logError(e)
        this.setState({ signOutError: 'You are not signed in' })
      })
  }

  performSignOut = () => {
    const { deviceType, contractor, signedOutSiteType } = this.props

    this.setState({ loading: true })
    signOut(deviceType, contractor, signedOutSiteType, this.signOutCallback)
  }

  signOutCallback = (response) => {
    const { history } = this.props

    if (response.isError) {
      if (!response.currentlySignedIn) {
        this.setState({ signOutError: 'You are not currently signed in', loading: false })
        setTimeout(() => {
          history.push('/signin')
        }, 3000)
      } else {
        this.setState({ signOutError: response.error, loading: false })
      }
    } else {
      history.push({
        pathname: '/confirmation',
        state: { signIn: false }
      })
    }
  }

  render() {
    const { loading, siteName } = this.state

    return (
      <Container className="ln-u-text-align-center u-no-scroll__vertical">
        <div className="ln-o-grid ln-o-grid--matrix c-button-menu">
          <div className="ln-o-grid__item ln-u-1/3@xs ln-u-1/1 ln-u-text-align-center c-button-menu__item" />
          <div className="ln-o-grid__item ln-u-1/3@xs ln-u-1/1 ln-u-text-align-center c-button-menu__item">
            {loading ? <LoadingOverlay text="Signing you out" /> : <SignOutButton onClick={this.performSignOut} />}
            <div className="ln-u-text-align-center ln-u-push-top ln-u-soft-bottom">
              {loading === false
                && <Container className="u-font-weight__bold">{siteName}</Container>
              }
            </div>
            <div>
              {this.signOutStatus()}
            </div>
          </div>
          <div className="ln-o-grid__item ln-u-1/3@xs ln-u-1/1 ln-u-text-align-center c-button-menu__item" />
        </div>
      </Container>
    )
  }
}

SignOut.propTypes = {
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.shape({
    userName: PropTypes.string.isRequired
  }).isRequired,
  contractor: PropTypes.shape({
    username: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  deviceType: PropTypes.number.isRequired,
  signedOutSiteType: PropTypes.string.isRequired
}

export default SignOut
