import actions from './actions'

const user = (state = { openVisit: { workOrderNumber: null, visitPass: null, visitPasses: [] } }, action) => {
  switch (action.type) {
    case actions.setVisit:
      return { ...state, openVisit: action.visit }
    case actions.setVisitWorkOrder:
      return { ...state, openVisit: { ...state.openVisit, workOrderNumber: action.workOrder, workOrderCompanyName: action.workOrderCompanyName } }
    case actions.setVisitPass:
      return { ...state, openVisit: { ...state.openVisit, visitPass: action.visitPass } }
    case actions.setVisitPasses:
      return { ...state, openVisit: { ...state.openVisit, visitPasses: [...state.openVisit.visitPasses, action.visitPass] } }
    case actions.removeVisitPass:
      return {
        ...state, openVisit: {
          ...state.openVisit, visitPasses: state.openVisit.visitPasses.filter((obj) => {
            return obj.passTypeId !== action.visitPass.passTypeId
          })
        }
      }
    default:
      return state
  }
}

export default user
