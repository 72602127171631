import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
  OutlinedButton,
  Display4,
  Display2
} from '@jsluna/react'

import WithFooter from '../Footer'
import FooterWithButton from '../Footer/FooterWithButton'
import WorkOrderContainer from '../WorkOrderContainer'
import redirectUser from '../../utils/routeHelper'
import PassView from '../Passes/PassView'
import { visitPassActionTypes } from '../../utils/constants'
import LoadingOverlay from '../LoadingOverlay'

import { ConfigureSignalRConnection, stopSignalRConnection } from '../../utils/signalR'
import { setAppRestartCallback } from '../../utils/appHelper'

class WorkOrderHomePage extends Component {
  constructor(props) {
    super(props)
    const { setBurgerHeader, setSignOutHeader } = props

    this.state = {
      bannerStatus: 'pending',
      bannerText: '',
      loading: true,
      isStateSet: false,
      isPassRejected: false,
      signalRListening: false
    }
    setBurgerHeader()
    setSignOutHeader(true)

    this.signalRConnection = null
  }

  componentDidMount() {
    const { history } = this.props
    window.scrollTo(0, 0)
    setAppRestartCallback(this.appDidReopen)
    redirectUser(history)
  }

  componentWillReceiveProps = (newProps) => {
    const { visitPasses } = this.props

    if (visitPasses != null
      && visitPasses.length > 0
      && newProps.visitPasses != null
      && newProps.visitPasses.length > 0) {
      this.checkPasses()
    }
  }

  componentDidUpdate() {
    const { isStateSet } = this.state
    if (isStateSet === false) {
      this.checkPasses()
    }
  }

  componentWillUnmount() {
    setAppRestartCallback(null)

    if (this.signalRConnection) {
      stopSignalRConnection(this.signalRConnection)
    }
  }

  appDidReopen = () => {
    const { history } = this.props

    if (this.signalRConnection) {
      stopSignalRConnection(this.signalRConnection)
    }

    this.setState({ signalRListening: false })
    redirectUser(history)
    this.checkPasses()
  }

  signalRCallBack = () => {
    const { history } = this.props
    redirectUser(history)
  }

  checkPasses = () => {
    const { visitPasses, visitId } = this.props
    const { signalRListening } = this.state

    let authorisedText = ''
    if (visitPasses) {
      authorisedText = visitPasses.length > 1 ? 'Passes authorised - start work' : 'Pass authorised - start work'
    }

    if (visitPasses && visitPasses.length !== 0 && visitPasses[0].passTypeId !== -1) {
      const rejectedPasses = visitPasses.filter((p => p.recentActionTypeId === visitPassActionTypes.rejected))
      const authorisedPasses = visitPasses.filter((p => p.recentActionTypeId === visitPassActionTypes.approved))

      if (rejectedPasses.length > 0) {
        this.setState({
          isPassRejected: true, bannerStatus: 'rejected', isStateSet: true, bannerText: '', loading: false, signalRListening: false
        })
      } else if (authorisedPasses.length === visitPasses.length) { // bannerStatus defaults to pending in state
        this.setState({
          isPassRejected: false, bannerStatus: 'authorised', isStateSet: true, bannerText: authorisedText, loading: false, signalRListening: false
        })
      } else if (!signalRListening) {
        // Pass is pending - setup a signal r connection and wait for auth/reject
        this.signalRConnection = ConfigureSignalRConnection(this.signalRCallBack, visitId)
      }
      this.setState({ isStateSet: true, loading: false, signalRListening: true })
    } else { // user clicked that they dont require a pass
      this.setState({ bannerStatus: 'authorised', isStateSet: true, loading: false })
    }
  }

  handleSelectClick = () => {
    const { history } = this.props
    history.push('/workorders/true')
  }

  // Creates a static footer with a button
  getFooter = () => {
    const { selectedWorkOrder, workOrders } = this.props

    return (
      ((selectedWorkOrder === '' || selectedWorkOrder === null) && workOrders.length > 0)
      && (
        <FooterWithButton
          button={<OutlinedButton className="ln-c-button ln-c-button--full" fullWidth onClick={this.handleSelectClick}>Select a work order</OutlinedButton>}
        />
      )
    )
  }

  setLoading = (loading) => {
    this.setState({ loading })
  }

  displayWorkOrderSection = () => {
    const { bannerText, bannerStatus, loading } = this.state
    const {
      selectedWorkOrder, workOrders, visitPasses, history, hasPassesHandedBack, siteType
    } = this.props
    let passes = []
    if (visitPasses) {
      passes = visitPasses.filter(p => p.passTypeId > 0)
    }

    if (hasPassesHandedBack && loading) {
      this.setState({ loading: false })
    }

    return (
      <WorkOrderContainer
        workOrdersLength={workOrders.length}
        workOrderNumber={selectedWorkOrder}
        status={bannerStatus}
        permits={this.getPassView(passes)}
        text={bannerText}
        history={history}
        hasPassesHandedBack={hasPassesHandedBack}
        loadingCallback={this.setLoading}
        siteType={siteType}
      />
    )
  }

  getPassView = (passes) => {
    const { passTypes } = this.props
    const { isPassRejected } = this.state
    const viewPasses = []
    if (passes && !isPassRejected) {
      passes.forEach(
        (v) => {
          const name = passTypes.find(pt => pt.id === v.passTypeId)
          if (name) {
            viewPasses.push({ passId: v.id, passName: name.name, actionTypeId: v.recentActionTypeId })
          }
        }
      )
    }

    return (
      <div>
        {viewPasses.map(pass => <PassView pass={pass} key={pass.passId} />)}
      </div>
    )
  }

  render() {
    const { signedInSite } = this.props
    const { loading } = this.state

    return (
      <WithFooter className="ln-c-card" footer={this.getFooter()}>
        <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom*3 ln-u-padding-top">
          <div className="ln-u-margin-top*4 ln-u-margin-bottom*4">
            <Display4 className="ln-u-hard-bottom">{'You\'re signed in'}</Display4>
            <br />
            <Display2 className="ln-u-hard-top">{signedInSite ? signedInSite.name : ''}</Display2>
          </div>
        </Container>
        {this.displayWorkOrderSection()}
        {loading
          && <LoadingOverlay hasBackground text="Loading..." />
        }
      </WithFooter>
    )
  }
}

WorkOrderHomePage.propTypes = {
  signedInSite: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  selectedWorkOrder: PropTypes.string,
  setBurgerHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  passTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  visitPasses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  workOrders: PropTypes.arrayOf(PropTypes.shape({
    workOrderNumber: PropTypes.string,
  })).isRequired,
  hasPassesHandedBack: PropTypes.bool.isRequired,
  visitId: PropTypes.number.isRequired,
  siteType: PropTypes.string,
}

WorkOrderHomePage.defaultProps = {
  selectedWorkOrder: '',
  signedInSite: {
    name: ''
  },
  passTypes: [],
  visitPasses: [],
  siteType: null
}

export default WorkOrderHomePage
