import React from 'react'
import { Container, List } from '@jsluna/react'

const RAMSItems = [
  `You have been signed out of site as you couldn't confirm RAMS were in place`,
  `Do not start any work which doesn't have RAMS`
]

const RAMSDeclinePolicy = () => {
  return (
    <Container>
      <h3>Declined RAMS</h3>
      <List items={RAMSItems} spaced />
    </Container>
  )
}

export default RAMSDeclinePolicy
