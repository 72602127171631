const actions = {
  setVisit: 'SET::SET_VISIT',
  setVisitWorkOrder: 'SET::SET_VISIT_WORKORDER',
  setVisitPass: 'SET::SET_VISIT_PASS',
  setVisitPasses: 'SET::SET_VISIT_PASSES',
  removeVisitPass: 'SET::REMOVE_VISIT_PASS'
}

export const setVisit = visit => ({ type: actions.setVisit, visit })
export const setVisitWorkOrder = (workOrder, workOrderCompanyName = null) => ({ type: actions.setVisitWorkOrder, workOrder, workOrderCompanyName })
export const setSelectedPass = visitPass => ({ type: actions.setVisitPass, visitPass })
export const setSelectedPasses = visitPass => ({ type: actions.setVisitPasses, visitPass })
export const removePassFromVisitPasses = visitPass => ({ type: actions.removeVisitPass, visitPass })

export default actions
