import React from 'react'
import { Button, Heading4 } from '@jsluna/react'
import { PropTypes } from 'prop-types'

const MenuButton = ({ children, text, onClick, enabled }) => (
  <Button className="c-button-menu__item--fill" onClick={onClick}>
    <svg height="80%" viewBox="0 0 200 200">
      <g fill="none" fillRule="evenodd">
        <circle cx="100" cy="100" stroke={enabled ? '#f06c00' : '#8e8e8e'} r="95" stroke-width="4%" />
        {children}
      </g>
    </svg>
    {text !== '' && <Heading4 className={enabled ? 'ln-u-color-orange' : 'ln-u-color-grey'}>{text}</Heading4>}
  </Button>
)

MenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired
}

MenuButton.defaultProps = {
  text: ''
}

export default MenuButton
