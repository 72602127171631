import actions from './actions'

const rememberMe = (state = { keepLoggedIn: false }, action) => {
  switch (action.type) {
    case actions.set:
      return { ...state, keepLoggedIn: action.keepLoggedIn }
    default:
      return state
  }
}

export default rememberMe
