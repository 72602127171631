import React from 'react'
import { Modal, ModalHeading } from '@jsluna/react'
import { PropTypes } from 'prop-types'

const errorModal = (props) => {
  const {
 isOpen, title, details, handleClose
} = props

  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <ModalHeading element="h5">{title}</ModalHeading>
      <p>{details}</p>
    </Modal>
  )
}

errorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default errorModal
