import React from 'react'
import {
  Button, OutlinedButton, Text, FlagComponent, FlagWrapper, FlagBody
} from '@jsluna/react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
import menuIcon from '../../assets/menu_24px.svg'
import leftArrowIcon from '../../assets/left-arrow_24px.svg'
import LoadingOverlay from '../LoadingOverlay'
import ErrorModal from '../ErrorModal'
import PreventSignOutModal from '../Passes/PreventSignOutModal'
import signOut from '../../utils/signOutHelper'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.state = {
      signingOut: false
    }
  }

  menuClicked = () => {
    const { toggleMenu, menuOpen } = this.props

    if (menuOpen) {
      toggleMenu(false)
    } else {
      toggleMenu(true)
    }
  }

  performSignOut = () => {
    const { loggedInUser, deviceType, signedOutSiteType } = this.props

    this.setState({ signingOut: true })
    signOut(deviceType, loggedInUser.user, signedOutSiteType, this.signOutCallback)
  }

  signOutCallback = (response) => {
    this.setState({ signingOut: false })

    const { history } = this.props

    if (response.isError) {
      if (!response.currentlySignedIn) {
        history.push('/signin')
      } else {
        this.setState({ signOutError: response.error, passesHandedBack: response.passesHandedBack, loading: false })
      }
    } else {
      history.push({
        pathname: '/confirmation',
        state: { signIn: false }
      })
    }
  }

  renderSigningOutOverlay = () => {
    const { signingOut } = this.state

    if (signingOut) {
      return <LoadingOverlay hasBackground text="Signing you out" />
    }
    return ''
  }

  renderSignOutError = () => {
    const { signOutError, passesHandedBack } = this.state

    if (signOutError) {
      return <ErrorModal
        isOpen={signOutError != null && passesHandedBack === true}
        title="Unable to sign out"
        details={signOutError}
        handleClose={() => this.setState({signOutError: null})} />
    }
    return ''
  }

  closeModal = () => {
    this.setState({ signOutError: null })
  }

  goBack() {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { header, visitPasses } = this.props
    const { signOutError, passesHandedBack } = this.state

    return (
      <div>
        <PreventSignOutModal
          isOpen={signOutError != null && passesHandedBack === false}
          handleClose={this.closeModal}
          okClick={this.closeModal}
          isPlural={visitPasses && visitPasses.length > 1}
        />
        <div className="ln-o-flag c-header-wrapper">
          {this.renderSigningOutOverlay()}
          {this.renderSignOutError()}
          <div className="ln-o-flag__component">
            <div className="ln-u-padding-top*1/2">
              {header.burger === true && (
                <Button onClick={this.menuClicked}>
                  <img src={menuIcon} alt="menu icon" />
                </Button>
              )}
              {header.back === true && (
                <Button onClick={this.goBack} className="ln-u-hard">
                  <img src={leftArrowIcon} alt="left arrow icon" />
                </Button>
              )}
            </div>
          </div>
          <div className="ln-o-flag__body">
            <FlagWrapper className="ln-u-padding-top*1/2 ln-u-padding-bottom*1/2 ln-u-padding-right*2">
              <FlagBody>
                <div className="ln-u-soft-right ln-u-padding-top">
                  <Text className="c-header ln-u-padding-top*1/2">Sign-in book</Text>
                </div>
              </FlagBody>
              <FlagComponent>
                {header.signOut === true
                  && <OutlinedButton onClick={this.performSignOut}>Sign out</OutlinedButton>
                }
              </FlagComponent>
            </FlagWrapper>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  header: PropTypes.shape({
    burger: PropTypes.bool.isRequired,
    back: PropTypes.bool.isRequired,
    signOut: PropTypes.bool.isRequired
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  toggleMenu: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string,
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  deviceType: PropTypes.number.isRequired,
  signedOutSiteType: PropTypes.string.isRequired,
  visitPasses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }))
}

Header.defaultProps = {
  header: {
    burger: true,
    back: false
  },
  visitPasses: []
}

export default withRouter(Header)
