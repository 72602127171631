import { connect } from 'react-redux'
import { decorator as reduxBurgerMenu, action as toggleMenu } from 'redux-burger-menu'
import { withRouter } from 'react-router'
import Menu from './Menu'

const mapStateToProps = state => ({
  user: state.loggedInUser.user,
  isOpen: state.burgerMenu.isOpen
})

const mapDispatchToProps = dispatch => ({
  toggleMenu: (state) => {
    dispatch(toggleMenu(state))
  }
})

export default withRouter(reduxBurgerMenu(connect(mapStateToProps, mapDispatchToProps)(Menu)))
