import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
  Text,
  FilledButton,
  FormGroup,
  TextInputField
} from '@jsluna/react'

import api from '../../../utils/api'
import Logger from '../../../utils/logger'
import LoadingOverlay from '../../LoadingOverlay'
import EmailConfirmationModal from '../../Email/EmailConfirmationModal'

class ForgottenPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      btnDisabled: true,
      email: '',
      loading: false,
      isModalOpen: false,
      modalType: '',
      userNotRecognised: false
    }
  }

  componentDidMount() {
    const { setHeaderBack } = this.props

    setHeaderBack()
  }

  handleButtonClick = async () => {
    const { email } = this.state

    this.setState({ loading: true })

    await api.checkEmailVerified(email)
      .then((result) => {
        if (result.status === 200) {
          this.setState({ isModalOpen: true, modalType: 'emailConfirmed', userNotRecognised: false })
        } else {
          this.setState({ userNotRecognised: true, btnDisabled: true })
        }
      }).catch((error) => {
        Logger.logError(error)
      })
    this.setState({ loading: false })
  }

  handleUsernameChange = (e) => {
    this.setState({ userNotRecognised: false })
    if (e.target.value !== '') {
      this.setState({ btnDisabled: false })
    } else {
      this.setState({ btnDisabled: true })
    }
    this.setState({ email: e.target.value })
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      email: '',
      btnDisabled: true
    })
  }

  showModal = (type) => {
    const { isModalOpen, email } = this.state
    const { history } = this.props
    let errorModal = false

    if (type === 'unauthorised') {
      errorModal = true
    }

    if (isModalOpen) {
      return (
        <EmailConfirmationModal isModalOpen={isModalOpen} history={history} errorModal={errorModal} email={email} type={type} closeModal={this.closeModal} />
      )
    }
    return null
  }

  render() {
    const {
      btnDisabled,
      loading,
      modalType,
      email,
      userNotRecognised
    } = this.state

    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">

        {this.showModal(modalType)}

        <h2>Forgotten password</h2>
        <div>
          <Text>If you've forgotten your password we can send you an email to reset it.</Text>
        </div>
        <div className="ln-u-margin-top*2">
          <Text>Enter your user name and we'll send the email to the one you registered with.</Text>
        </div>
        <div className="ln-u-margin-top*2">
          <FormGroup
            className="ln-u-1/2@xs"
            name="formConfirmPassword"
            label="Username"
          >
            <TextInputField
              type="text"
              name="txtUsername"
              className=""
              value={email}
              onChange={e => this.handleUsernameChange(e)}
            />
          </FormGroup>
          {userNotRecognised
            && (
              <FormGroup
                name="formConfirmPasswordError"
                className="u-margin-top__minus1rem"
              >
                <span />
              </FormGroup>
            )
          }
          <div className="ln-u-1/3@xs">
            <div className="ln-u-margin-top*3">
              <FilledButton fullWidth disabled={btnDisabled} onClick={() => this.handleButtonClick()}>Reset my password</FilledButton>
            </div>
          </div>
        </div>
        {loading
          && <LoadingOverlay hasBackground />
        }
      </Container>
    )
  }
}

ForgottenPassword.propTypes = {
  setHeaderBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

}

export default ForgottenPassword
