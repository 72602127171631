import React from 'react'
import { PropTypes } from 'prop-types'
import { getRememberMe, getToken } from '../../utils/storage'
import LoadingOverlay from '../LoadingOverlay'
import redirectUser from '../../utils/routeHelper'
import { sendMessage } from '../../utils/appHelper'

const LoginCallback = (props) => {

  const { history } = props

  sendMessage('login', 'login')

  if (getRememberMe() === 'true' || (getToken() !== undefined && getToken() !== null)) {
    redirectUser(history)
  }
  return (
    <LoadingOverlay text="Logging you in..." />
  )
}


LoginCallback.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

export default LoginCallback
