import React from "react";
import { PropTypes } from "prop-types";
import {
  Card,
  Container,
  Display4,
  Display3,
  FlagWrapper,
  FlagComponent,
  FlagBody,
  OutlinedButton,
} from "@jsluna/react";
import errorIcon from "../../../assets/error.png";
import { ArrowRight } from "@jsluna/icons";

class WorkOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrders: [],
      searchWorkOrderList: [],
      searchKeyword: null,
    };
  }

  componentWillMount = () => {
    const {
      setHeaderBurger,
      setHeaderBack,
      setSignOutHeader,
      setWorkOrder,
      workOrders,
      match,
    } = this.props;
    const { withbackbutton } = match.params;

    if (withbackbutton === "true") {
      setHeaderBack();
    } else {
      setHeaderBurger();
    }
    setWorkOrder(null);
    setSignOutHeader(true);

    this.setState({ workOrders });
  };

  onSearchWorkorders = (search) => {
    this.setState({
      searchKeyword: search,
    });
    const { workOrders } = this.state;
    const searchList = [];
    for (let i = 0; i < workOrders.length; i++) {
      if (workOrders[i].workOrderNumber.includes(search)) {
        searchList.push(workOrders[i]);
      }
    }
    this.setState({
      searchWorkOrderList: searchList,
    });
  };

  displayWorkOrders = () => {
    const { workOrders, searchWorkOrderList, searchKeyword } = this.state;
    if (searchKeyword) {
      if (searchWorkOrderList.length > 0) {
        return searchWorkOrderList.map((workOrder) => (
          <div
            className="u-margin-bottom__small"
            key={workOrder.workOrderNumber}
          >
            <Card
              className="ln-u-soft"
              onClick={() => this.handleWorkOrderSelection(workOrder)}
            >
              <FlagWrapper>
                <FlagComponent>
                  <Display3>{workOrder.workOrderNumber}</Display3>
                  <p className="ln-u-flush">{workOrder.companyName}</p>
                </FlagComponent>
                <FlagBody className="ln-u-text-align-right">
                  <ArrowRight />
                </FlagBody>
              </FlagWrapper>
            </Card>
          </div>
        ));
      } else {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ marginLeft: "45%" }}>
                <img src={errorIcon} />
              </div>
              <div>
                <p style={{ textAlign: "center" }}>
                  Sorry, we can't find this work order
                </p>
              </div>
            </div>
          </>
        );
      }

      // eslint-disable-next-line no-else-return
    } else {
      // eslint-disable-next-line arrow-parens
      return workOrders.map((workOrder) => (
        <div className="u-margin-bottom__small" key={workOrder.workOrderNumber}>
          <Card
            className="ln-u-soft"
            onClick={() => this.handleWorkOrderSelection(workOrder)}
          >
            <FlagWrapper>
              <FlagComponent>
                <Display3>{workOrder.workOrderNumber}</Display3>
                <p className="ln-u-flush">{workOrder.companyName}</p>
              </FlagComponent>
              <FlagBody className="ln-u-text-align-right">
                <ArrowRight />
              </FlagBody>
            </FlagWrapper>
          </Card>
        </div>
      ));
    }
  };

  handleWorkOrderSelection = (workOrder) => {
    const { history, setWorkOrder } = this.props;
    setWorkOrder(workOrder.workOrderNumber, workOrder.companyName);

    history.push("/AssessmentStatements");
  };

  handleDoSomethingElse = () => {
    const { history, setWorkOrder } = this.props;
    setWorkOrder(null);
    history.push("/WorkOrderHome");
  };

  render() {
    return (
      <Container className="ln-u-padding-top ln-u-padding-bottom">
        <div className="ln-u-margin-ends*4 ln-u-margin-sides*2">
          <Display4>Select the work order that you&#39;ve come to do</Display4>
        </div>

        <div
          className="ln-c-form-group"
          style={{ padding: "0px 10px 0px 10px" }}
        >
          <label
            htmlFor="search-field-3"
            className="ln-c-label ln-u-visually-hidden"
          >
            Search products
          </label>
          <div className="ln-c-input-group ln-c-input-group--has-icon">
            <svg
              aria-hidden="true"
              className="ln-c-input-group__icon ln-c-icon"
              role="img"
              viewBox="0 0 24 24"
            >
              <path
                d="M9.692 15.333a5.641 5.641 0 1 0 0-11.282 5.641 5.641 0 0 0 0 11.282zm6.117-.975l5.89 5.891a1.026 1.026 0 0 1-1.45 1.45l-5.89-5.89a7.692 7.692 0 1 1 1.45-1.45z"
                fill="currentColor"
                fillRule="nonzero"
              ></path>
            </svg>
            <input
              pattern="/^-?\d+\.?\d*$/"
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              type="number"
              id="search-field-3"
              name="search-field-3"
              className="ln-c-text-input ln-c-input-group__control"
              placeholder="Find a work order"
              onChange={(e) => {
                this.onSearchWorkorders(e.target.value);
              }}
            />
          </div>
        </div>
        {this.displayWorkOrders()}
        <div className="ln-u-margin-ends*4 ln-u-margin-sides*2">
          <OutlinedButton
            className="ln-c-button ln-c-button--full"
            onClick={this.handleDoSomethingElse}
            fullWidth
          >
            I&#39;ve come to do something else
          </OutlinedButton>
        </div>
      </Container>
    );
  }
}

WorkOrders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setHeaderBurger: PropTypes.func.isRequired,
  setHeaderBack: PropTypes.func.isRequired,
  setWorkOrder: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  workOrders: PropTypes.arrayOf(
    PropTypes.shape({
      workOrderNumber: PropTypes.string,
    }).isRequired
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      withbackbutton: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default WorkOrders;
