import React from 'react'
import { PropTypes } from 'prop-types'
import MenuButton from './MenuButton'

const SignOutButton = props => (
  <MenuButton text="Sign out" onClick={() => props.onClick()} enabled={props.enabled}>
    <g fill="#fff" fillRule="nonzero">
      {/* eslint-disable */}
        <path d="m125.503233 112.215814c0 2.805909 2.271497 5.080547 5.07353 5.080547s5.073529-2.274638 5.073529-5.080547v-25.4247498c0-2.8059085-2.271496-5.0805467-5.073529-5.0805467h-25.370125c-2.802033 0-5.073529 2.2746382-5.073529 5.0805467s2.271496 5.0805468 5.073529 5.0805468h20.296595z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 104.889236 -54.218139)" />
        <path d="m124.89017 78.1270905-35.5147057 35.5638275c-1.9813364 1.984077-1.9813364 5.200901 0 7.184978 1.9813365 1.984077 5.1937177 1.984077 7.1750541 0l35.5147056-35.5638274c1.981337-1.9840769 1.981337-5.2009011 0-7.1849781-1.981336-1.9840769-5.193717-1.9840769-7.175054 0z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 102.787418 -49.147793)" />
        <path d="m118.886324 132.193515c2.802032 0 5.073529 2.271496 5.073529 5.073529s-2.271497 5.07353-5.073529 5.07353h-56.3127946c-2.8020329 0-5.0735294-2.271497-5.0735294-5.07353v-74.6924999c0-2.8020329 2.2714965-5.0735294 5.0735294-5.0735294h56.3127946c2.802032 0 5.073529 2.2714965 5.073529 5.0735294s-2.271497 5.0735294-5.073529 5.0735294h-51.2392652v64.5454415z" />
        {/* eslint-enable */}
    </g>
  </MenuButton>
)

SignOutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool
}

SignOutButton.defaultProps = {
  enabled: true
}
export default SignOutButton
