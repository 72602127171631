import React from 'react'
import { Container, List } from '@jsluna/react'

const RAMSItems = [
  `Risk Assessment and Method Statements (RAMS) must exist to cover the work you are completing on site today`,
  `RAMS must not have expired`,
  `Work not covered within generic RAMS must have task specific RAMS produced`,
  `RAMS should be readily available for review and inspection at all times`,
  `If you are not sure if the work you are on site to complete is covered by RAMS in place then you must decline this statement, sign out of site and not complete the work`
]

const RAMSSafetyPolicy = () => {
  return (
    <Container>
      <h3>RAMS Safety Statement</h3>
      <List items={RAMSItems} spaced />
    </Container>
  )
}

export default RAMSSafetyPolicy
