import actions from './actions'

const taskTime = (state = {taskTimeSelected:{hours:[],minutes:[]}}, action) => {
    switch(action.type){
        case actions.setTime:
           
            return { ...state, taskTimeSelected:action.taskTimeSelected }
        default:
            return state
  }
}


export default taskTime 