import { getToken, isTokenExpired } from './storage'

const Console = console

const getAuthHeader = (needsAuthorization) => {
  const token = getToken()
  if (needsAuthorization && token) {
    return {
      Authorization: `Bearer ${JSON.parse(token).token}`
    }
  }
  return {}
}

const createRequest = (endpoint, operationType, needsAuthorization,
  {
    body,
    method = operationType,
    headers = { 'Content-Type': 'application/json', Accept: 'application/json' },
    mode = 'cors',
    encType = null
  }) => fetch(endpoint, {
  body,
  method,
  encType,
  mode,
  credentials: 'include',
  headers: {
    pragma: 'no-cache',
    'cache-control': 'no-cache',
    ...headers,
    ...getAuthHeader(needsAuthorization)
  }
})

const fetchRequest = (url, operationType, needsAuthorization, requestOptions = {}) => createRequest(url, operationType, needsAuthorization, requestOptions)
  .then((response) => {
    if (needsAuthorization === true && response.status === 401) {
      if (isTokenExpired()) {
        throw Object.assign({}, response, { loginRequired: true })
      }
    }
    return response
  })
  .catch((error) => {
    Console.log(error)
    throw error
  })

export default fetchRequest
