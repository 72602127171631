import React from 'react'
import { Modal, ModalHeading, FilledButton, Body1 } from '@jsluna/react'
import { PropTypes } from 'prop-types'

const AlreadySignedInModal = (props) => {
  const {
 isOpen, title, details, okClick
} = props

  return (
    <Modal open={isOpen} restrictClose>
      <ModalHeading element="h5">{title}</ModalHeading>
      <div className="ln-u-padding-top ln-u-padding-bottom*2">
        <Body1>{details}</Body1>
      </div>
      <FilledButton fullWidth onClick={okClick}>Ok</FilledButton>
    </Modal>
  )
}

AlreadySignedInModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  okClick: PropTypes.func.isRequired
}

AlreadySignedInModal.defaultProps = {
  isOpen: false
}

export default AlreadySignedInModal
