import React from 'react'

import { Container, Card } from '@jsluna/react'

import RedErrorMessage from '../RedErrorMessage'

import SainsburysDSIBHeader from '../SainsburysDSIBHeader'

const TokenUsed = () => {
  return (
    <div className="ln-u-bg-color-white ln-o-page">
      <SainsburysDSIBHeader />
      <Card>
        <RedErrorMessage text="Your password has already been changed" />
        <Container className="ln-u-margin-top*2">
          <p className="ln-u-margin-bottom*2">If you want to change your password again you must request this again via the Digital sign-in book app.</p>
        </Container>
      </Card>
    </div>
  )
}

export default TokenUsed
