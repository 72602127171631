import React from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
  Password,
  FilledButton,
  Checkbox,
  FormGroup,
  TextButton,
  Link,
  ProgressSpinner,
  ProgressIndicator,
} from '@jsluna/react'

import api from '../../utils/api'
import userManager from '../../utils/identity'
import {
  getRememberMe,
  setRememberMe,
  isTokenExpired,
  removeToken,
} from '../../utils/storage'
import LoadingOverlay from '../LoadingOverlay'
import EmailConfirmationModal from '../Email/EmailConfirmationModal'
import Logger from '../../utils/logger'
import redirectUser from '../../utils/routeHelper'
import { deviceTypes } from '../../utils/device'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      validCredentials: true,
      rememberMeChecked: false,
      emailHasBeenVerified: null,
      loading: false,
      isModalOpen: false,
      loadingPage: true,
      isActive: true
    }
  }

  componentWillMount() {
    const {
      setHeader,
      setLoggedInUser,
      setSignOutHeader,
      history,
      loggedOut
    } = this.props

    setHeader()
    setSignOutHeader(false)

    if (loggedOut) {
      this.setState({ loadingPage: false })
      removeToken()
      setRememberMe(false)
      setLoggedInUser(null)
    }
    else if (!isTokenExpired() || getRememberMe() === 'true') {
      this.getDeviceType()
      redirectUser(history)
    }
    else {
      this.setState({ loadingPage: false })
      this.getDeviceType()
      setLoggedInUser(null)
    }
  }

  getDeviceType = () => {
    const urlSearchString = window.location.search
    const { setDeviceType, deviceType } = this.props

    const search = new URLSearchParams(urlSearchString)

    if (urlSearchString && !deviceType) {
      if (search.get('devicetype') === 'mobileapp') {
        setDeviceType(deviceTypes.MobileApp)
      }
    } else if (!deviceType) {
      setDeviceType(deviceTypes.MobileWebsite)
    }
  }

  handleUserNameChanges = (e) => {
    if (this.state.isActive === false) {
      this.setState({ isActive: true })
    }
    this.setState({ username: e.target.value.trim() })
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  isValidateForm = () => {
    const { username, password, isActive } = this.state
    if (password && username && isActive) {
      return true
    }
    return false
  }

  handleSubmit = () => {
    const { username, password, rememberMeChecked } = this.state
    const { setUserName } = this.props
    this.setState({ loading: true })
    const user = {
      username,
      password,
      RememberMe: rememberMeChecked
    }

    setRememberMe(rememberMeChecked)

    api
      .login(user)
      .then((res) => {
        setUserName(username)
        if (res.status === 400) {
          this.setState({ validCredentials: false, loading: false })
          setRememberMe(false)
          return
        }
        else if (res.status === 401) {
          this.setState({
            emailHasBeenVerified: false,
            loading: false,
            isModalOpen: true
          })
          setRememberMe(false)
          return
        }
        else if (res.status === 403) {
          this.setState({
            loading: false,
            isActive: false
          })
          setRememberMe(false)
          return
        }

        this.setState({ validCredentials: true, emailHasBeenVerified: true })
        userManager.signinRedirect()
      })
      .catch((error) => {
        Logger.logError(error)
      })
  }

  toggleRememberMe = (e) => {
    this.setState({ rememberMeChecked: e.target.checked })
  }

  handleRegisterBtn = () => {
    const { history } = this.props
    history.push('/register')
  }

  handleForgottenPasswordClick = () => {
    const { history } = this.props
    this.setState(
      { username: '', password: '', rememberMeChecked: false },
      history.push('/forgottenpassword')
    )
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      username: '',
      password: '',
      validCredentials: true,
      rememberMeChecked: false
    })
  }

  showModal = () => {
    const { emailHasBeenVerified, isModalOpen } = this.state
    const { history, user } = this.props
    if (!emailHasBeenVerified) {
      return (
        <EmailConfirmationModal
          isModalOpen={isModalOpen}
          history={history}
          errorModal
          email={user.userName}
          closeModal={this.closeModal}
        />
      )
    }
    return null
  }

  render() {
    const {
      username,
      password,
      validCredentials,
      loading,
      rememberMeChecked,
    } = this.state

    return (
      <React.Fragment>
        {this.state.loadingPage && (
          <ProgressIndicator page loading className='c-loadingOverlay'>
            <ProgressSpinner />
          </ProgressIndicator>
        )}
        {!this.state.loadingPage && (
          <Container className='ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2'>
            {this.showModal()}
            <h2>Log in</h2>
            <Container size='xs'>
              <div className='ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right'>
                <label htmlFor='txtUsername' className='ln-c-label'>
                  Username
                </label>
                <input
                  type='text'
                  name='txtUsername'
                  className='ln-c-text-input'
                  onChange={(e) => this.handleUserNameChanges(e)}
                  value={username}
                />
              </div>
              <div className='ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right'>
                <label htmlFor='txtPassword' className='ln-c-label'>
                  Password
                </label>
                <Password
                  type='text'
                  className='ln-c-text-input'
                  name='txtPassword'
                  value={password}
                  onChange={(e) => this.handlePasswordChange(e)}
                />
              </div>
              <Checkbox
                className='c-check-box-margin__large-screen'
                name='checkRememberMe'
                checked={rememberMeChecked}
                label='Remember me on this device'
                onChange={this.toggleRememberMe}
              />
              <div className='ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top'>
                <div className='ln-o-grid__item ln-u-1/1 ln-u-1/3@xs'>
                  <FilledButton
                    id='btnLogin'
                    className='ln-c-button
                ln-c-button--full
                ln-c-button--primary'
                    type='button'
                    onClick={this.handleSubmit}
                    disabled={!this.isValidateForm()}
                  >
                    Log in
                  </FilledButton>
                </div>
              </div>
              <div className='ln-u-soft-left ln-u-soft-top'>
                {validCredentials === false && (
                  <FormGroup
                    name='formPassword'
                    error='Invalid Username or Password'
                  />
                )}
              </div>
              <div className='ln-o-grid__item ln-u-1/3@xs ln-u-1/1 ln-u-text-align-center'>
                <TextButton onClick={() => this.handleRegisterBtn()}>
                  Register
                </TextButton>
              </div>
            </Container>
            <div className='ln-o-grid__item ln-u-1/3@xs ln-u-1/1 ln-u-text-align-center ln-u-padding-top*2'>
              <Link href='' onClick={() => this.handleForgottenPasswordClick()}>
                Forgotten your password?
              </Link>
            </div>
            {loading && <LoadingOverlay hasBackground />}
          </Container>
        )}
      </React.Fragment>
    )
  }
}

LoginForm.propTypes = {
  setDeviceType: PropTypes.func.isRequired,
  deviceType: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setUserName: PropTypes.func.isRequired,
  setLoggedInUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userName: PropTypes.string.isRequired,
  }).isRequired,
  loggedOut: PropTypes.bool
}

LoginForm.defaultProps = {
  deviceType: null,
  loggedOut: false
}

export default LoginForm
