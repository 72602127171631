import fetchAuth from './fetchAuth'
import apiBaseUrl from './apiConfig'
import userManager from './identity'

const Console = console

const handleError = (errorText, error) => {
  if (error.loginRequired) {
    userManager.signinRedirect()
  } else {
    Console.log(errorText)
    Console.log(error)
    throw error
  }
}

const api = {
  getAllEmployers() {
    const dataUrl = `${apiBaseUrl}/employers/all`
    return fetchAuth(dataUrl, 'GET', false)
      .then(response => response.json())
      .catch(error => handleError('Error getting all employers', error))
  },

  getContractorDetails() {
    const dataUrl = `${apiBaseUrl}/users/contractorDetails`
    return fetchAuth(dataUrl, 'GET', true)
      .then(response => response.json())
      .catch(error => handleError('Error getting contractorDetails', error))
  },

  getAllSuppliers() {
    const dataUrl = `${apiBaseUrl}/merchandiser/suppliers/all`
    return fetchAuth(dataUrl, 'GET', true)
      .then(response => response.json())
      .catch(error => handleError('Error getting all suppliers', error))
  },
  getAllTasks() {
    const dataUrl = `${apiBaseUrl}/merchandiser/tasks/all`
    return fetchAuth(dataUrl, 'GET', true)
      .then(response => response.json())
      .catch(error => handleError('Error getting all tasks', error))
  },

  saveVisitTask(visitTaskDetails){
    const dataUrl = `${apiBaseUrl}/merchandiser/SaveVisitsTaskforMerchandiser`
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(visitTaskDetails)
    })
    .then(response => response)
    .catch(error => handleError('Error saving visit task', error))
  },

  getJobRoles() {
    const dataUrl = `${apiBaseUrl}/contractors/jobroles`
    return fetchAuth(dataUrl, 'GET', false)
      .then(response => response.json())
      .catch(error => handleError('Error getting job roles', error))
  },

  getAssessmentStatements(siteType) {
    const dataUrl = `${apiBaseUrl}/workOrders/assessmentStatements/${siteType}`
    return fetchAuth(dataUrl, 'GET', true)
      .then(response => response.json())
      .catch(error => handleError('Error getting assessmentStatements', error))
  },

  getClosestProperties(lat, lon) {
    const dataUrl = `${apiBaseUrl}/properties/closest?lat=${lat}&lon=${lon}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        const { status } = response
        if (status === 200) {
          return response.json()
        }
        if (status === 408) { // timeout status code
          return status
        }
        return null
      })
      .catch(error => handleError('Error getting closest site', error))
  },


  login(credentials) {
    const dataUrl = `${apiBaseUrl}/users/login`
    return fetchAuth(dataUrl, 'POST', false, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(credentials)
    })
      .then(res => res)
      .catch(error => handleError('Error logging in', error))
  },

  getUserDetails() {
    const dataUrl = `${apiBaseUrl}/users/details`
    return fetchAuth(dataUrl, 'GET', true)
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        }
        return null
      })
      .catch(error => handleError('Error getting user details', error))
  },

  registerContractor(contractor) {
    const dataUrl = `${apiBaseUrl}/users/registermobileuser`

    return fetchAuth(dataUrl, 'POST', false, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(contractor)
    })
      .then(res => res)
      .catch((error) => {
        Console.log('Error registering contractor')
        Console.log(error)
      })
      .catch(error => handleError('Error adding contractor', error))
  },

  signIn(signIndetails) {
    const dataUrl = `${apiBaseUrl}/contractors/signin`

    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(signIndetails)
    })
      .then((res) => {
        if (res.status === 200 || res.status === 400) {
          return res.json()
        }
        return null
      })
      .catch(error => handleError('Error signing in', error))
  },

  addWorkOrder(visitId, workOrderNumber) {
    const dataUrl = `${apiBaseUrl}/visits/${visitId}/addWorkOrder`

    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(workOrderNumber)
    })
      .then((res) => {
        if (res.status === 200 || res.status === 400) {
          return res.json()
        }
        return null
      })
      .catch(error => handleError('Error adding work order', error))
  },

  removeWorkOrder(visitId) {
    const dataUrl = `${apiBaseUrl}/visits/${visitId}/removeWorkOrder`

    return fetchAuth(dataUrl, 'GET', true)
      .then((res) => {
        if (res.status === 200 || res.status === 400) {
          return res.json()
        }
        return null
      })
      .catch(error => handleError('Error removing work order', error))
  },

  getSiteName(userName) {
    const dataUrl = `${apiBaseUrl}/contractors/signedinsite`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        const { status } = response
        if (status === 200) {
          return response.json()
        }
        return null
      })
      .catch(error => handleError('Error getting site name', error))
  },

  getGeofenceRadius() {
    const dataUrl = `${apiBaseUrl}/app/geofenceRadius`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        const { status } = response
        if (status === 200) {
          return response.json()
        }
        return null
      })
      .catch(error => handleError('Error getting geofence radius', error))
  },

  signOut(signOutDetails) {
    const dataUrl = `${apiBaseUrl}/contractors/signout`

    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(signOutDetails)
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        }
        if (res.status === 403) {
          return { isValid: false, isAllowed: false }
        }
        return res
      })
      .catch(error => handleError('Error signing out', error))
  },

  logError(logLevel, errorInfo) {
    const log = {
      level: logLevel,
      message: errorInfo
    }
    const dataUrl = `${apiBaseUrl}/logger/log`
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify(log)
    })
      .then(() => {
        console.log('Error logged succesfully')
      })
      .catch(() => console.log('Error logging error'))
  },

  CheckEmailUsernameExistsAndActive(emailaddress) {
    const dataUrl = `${apiBaseUrl}/users/emailExists`
    const emailId = {
      email: emailaddress
    }
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify(emailId)
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error checking if email username exists', error))
  },

  areWorkOrdersRequried(employerId) {
    const dataUrl = `${apiBaseUrl}/employers/${employerId}/hasWorkOrders`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError(`Error checking if employer ${employerId} requires work orders`, error))
  },

  getWorkOrdersBySite(siteCode, siteType) {
    const dataUrl = `${apiBaseUrl}/workorders/${siteCode}/${siteType}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting open work orders by site', error))
  },

  getPasses(siteType) {
    const dataUrl = `${apiBaseUrl}/passes/types/${siteType}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting passes', error))
  },

  getVisitPasses(visitId) {
    const dataUrl = `${apiBaseUrl}/passes/${visitId}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting visit passes', error))
  },

  addVisitPass(visitPass) {
    const dataUrl = `${apiBaseUrl}/passes/addvisitpass`
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(visitPass)
    })
      .then(response => response)
      .catch(error => handleError('Error adding a visit pass', error))
  },

  deleteVisitPass(visitPass) {
    const dataUrl = `${apiBaseUrl}/passes/delete`
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(visitPass)
    })
      .then(response => response)
      .catch(error => handleError('Error deleting a visit pass', error))
  },

  addVisitAction(action) {
    const dataUrl = `${apiBaseUrl}/passactions/submit`
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(action)
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error adding visit action', error))
  },

  getUserState() {
    const dataUrl = `${apiBaseUrl}/users/state`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting user state', error))
  },

  updateCarRegistration(registrationnumber) {
    const dataUrl = `${apiBaseUrl}/contractors/updateCarRegistration`
    return fetchAuth(dataUrl, 'POST', true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(registrationnumber)
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        }
        return response
      })
      .catch(error => handleError('Error updating car registration', error))
  },

  confirmEmail(userId, token) {
    const dataUrl = `${apiBaseUrl}/users/confirmEmail`
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify({ userId, token })
    })
      .then(response => response.json())
      .catch(error => handleError('Error confirming email address', error))
  },

  getPassQuestionById(passTypeId) {
    const dataUrl = `${apiBaseUrl}/passquestions/${passTypeId}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError(`Error getting pass questions by passTypeId: ${passTypeId}`, error))
  },

  checkEmailVerified(emailaddress) {
    const dataUrl = `${apiBaseUrl}/users/checkEmailVerified`
    const emailId = {
      email: emailaddress
    }
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify(emailId)
    })
      .then((response) => {
        return response
      })
      .catch(error => handleError(`Error checking email had been verified: ${emailaddress}`, error))
  },

  resetPassword(userId, token, password) {
    const dataUrl = `${apiBaseUrl}/users/resetpassword`
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify({ userId, token, password })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error resetting password', error))
  },

  checkTokenIsValid(userId, tokenProvider, purpose, token) {
    const dataUrl = `${apiBaseUrl}/users/VerifyUserToken`
    return fetchAuth(dataUrl, 'POST', false, {
      body: JSON.stringify({
        userId, tokenProvider, purpose, token
      })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error checking if token is valid', error))
  },

  getVisitPassJson(visitPassId) {
    const dataUrl = `${apiBaseUrl}/visitPass/${visitPassId}`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting visit pass json', error))
  },

  submitHandBackPasses(handBackPass) {
    const dataUrl = `${apiBaseUrl}/passes/handback`
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(handBackPass)
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error submitting handbackpasses', error))
  },

  getLatestTCs() {
    const dataUrl = `${apiBaseUrl}/TCs/getlatest`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting latest T&Cs', error))
  },

  userAcceptedTCs(contractorId, siteId) {
    const dataUrl = `${apiBaseUrl}/contractors/TCs/${siteId}/acceptedNewTCs`
    return fetchAuth(dataUrl, 'POST', true)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error accepting the new T&Cs', error))
  },

  getUsersLastAcceptedTCs(contractorId) {
    const dataUrl = `${apiBaseUrl}/contractors/TCs/lastaccepted`
    return fetchAuth(dataUrl, 'GET', true)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting users last accepted T&Cs', error))
  },

  getAllTermsAndConditions() {
    const dataUrl = `${apiBaseUrl}/TCs/getall`
    return fetchAuth(dataUrl, 'GET', false)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return response
      })
      .catch(error => handleError('Error getting Terms and Conditions', error))
  },

  validateAndResetPassword(userId, oldPassword, newPassword) {
    const dataUrl = `${apiBaseUrl}/users/validateUserAndResetPassword`
    const credentials = {
      userId,
      oldPassword,
      newPassword
    }
    return fetchAuth(dataUrl, 'POST', true, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(res => { return res.status })
      .catch(error => handleError('Error resetting password', error))
  },

  submitFeedback(appId, feedback, email) {
    const dataUrl = `${apiBaseUrl}/feedback/submit`
    const data = {
      appId,
      rating: feedback.rating,
      comments: feedback.comment,
      feedbackBy: email
    }
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response
      })
      .catch(error => handleError('Error submitting feedback', error))
  },

  showFeedback(appId, email) {
    const dataUrl = `${apiBaseUrl}/feedback/show`
    const data = { appId, feedbackBy: email }
    return fetchAuth(dataUrl, 'POST', true, {
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        var res = { show: false }
        return res
      })
      .catch(error => handleError('Error getting show feedback', error))
  }
}

export { api as default }
