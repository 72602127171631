import { connect } from 'react-redux'
import AssessmentStatements from './AssessmentStatements'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'

const mapStateToProps = state => ({
  selectedWorkOrder: state.visit.openVisit.workOrderNumber,
  visit: state.visit.openVisit,
  siteType: state.sainsburysSites.site ? state.sainsburysSites.site.siteType : null
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentStatements)
