import React from 'react'
import { ProgressIndicator, ProgressSpinner, Heading4 } from '@jsluna/react'
import { PropTypes } from 'prop-types'

const loadingOverlay = (props) => {
  const { text, hasBackground } = props

  return (
    <ProgressIndicator page loading className={hasBackground ? 'c-loadingOverlay-background' : 'c-loadingOverlay'}>
      <ProgressSpinner />
      <Heading4 className="ln-u-color-orange">{text}</Heading4>
    </ProgressIndicator>
  )
}


loadingOverlay.propTypes = {
  text: PropTypes.string,
  hasBackground: PropTypes.bool
}

loadingOverlay.defaultProps = {
  text: null,
  hasBackground: false
}

export default loadingOverlay
