import { connect } from 'react-redux'
import { action as toggleMenu } from 'redux-burger-menu'
import Header from './Header'

const mapStateToProps = state => ({
  header: state.header,
  menuOpen: state.burgerMenu.isOpen,
  loggedInUser: state.loggedInUser,
  deviceType: state.device.deviceType,
  visitPasses: state.visit.openVisit ? state.visit.openVisit.visitPasses : null,
  signedOutSiteType: state.sainsburysSites.site ? state.sainsburysSites.site.siteType : null
})

const mapDispatchToProps = dispatch => ({
  toggleMenu: (state) => {
    dispatch(toggleMenu(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
