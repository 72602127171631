import { connect } from 'react-redux'
import LoginForm from './LoginForm'
import { setHeaderBurger, setHeaderSignOut } from '../../store/header/actions'
import { setContractor } from '../../store/contractor/actions'
import { setRememberMe } from '../../store/remember/actions'
import { setUserName } from '../../store/user/actions'
import { setUser } from '../../store/loggedInUser/actions'
import { setSite } from '../../store/site/actions'
import { setDeviceType } from '../../store/device/actions'

const mapStateToProps = (state) => ({
  user: state.user,
  deviceType: state.device.deviceType,
})

const mapDispatchToProps = (dispatch) => ({
  setHeader: (state) => {
    dispatch(setHeaderBurger(state))
    dispatch(setContractor(null))
    dispatch(setRememberMe(false))
    dispatch(setSite(null))
  },
  setUserName: (state) => {
    dispatch(setUserName(state))
  },
  setLoggedInUser: (state) => {
    dispatch(setUser(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setDeviceType: (state) => {
    dispatch(setDeviceType(state))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
