import React from 'react'


const Footer = footer => (
  <div>
    <div className="c-footer-wrapper" />
    <div className="c-footer">{footer}</div>
  </div>
)

const WithFooter = (props) => {
  const { footer, children } = props
  return (
    <div>
      {children}
      {Footer(footer)}
    </div>
  )
}

export default WithFooter
