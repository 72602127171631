import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

import {
  Container,
  SelectField,
  FilledButton,
  AsyncAutocompleteField,
  FormGroup,
  Body1
} from '@jsluna/react'

import { Search } from '@jsluna/icons'
import toTitleCase from '../../utils/common'
import api from '../../utils/api'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props)
    const { contractor } = this.props
    this.state = {
      jobTitleListItems: [],
      forename: contractor === null ? '' : contractor.forename,
      surname: contractor === null ? '' : contractor.surname,
      email: contractor === null ? '' : contractor.email,
      employerId: contractor === null ? null : contractor.employerId,
      jobRoleId: contractor === null ? 0 : contractor.jobRoleId,
      telephoneNumber: contractor === null ? '' : contractor.telephoneNumber,
      vehicleRegistration: contractor === null ? '' : contractor.vehicleRegistration,
      emailAvailable: contractor !== null,
      emailBlured: contractor.email !== ''
    }
  }

  componentWillMount() {
    const { setHeader, setSignOutHeader } = this.props
    const { contractor, history } = this.props
    setHeader()
    setSignOutHeader(false)
    if (contractor == null || !contractor.acceptedTCs) {
      history.push('/register')
    } else if (contractor) {
      this.setState({ employerName: contractor.employerName })
    }
  }

  componentDidMount() {
    const { contractor } = this.props

    this.getJobTitleDropDownData()
    this.checkEmailExists(contractor.email)
    this.handleEmailError()

    if (contractor) {
      this.setState({ jobRoleId: contractor.jobRoleId })
    }

    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.siteContractorDetails()
  }

  getJobTitleDropDownData = () => {
    api.getJobRoles()
      .then((data) => {
        this.setState({ jobTitleListItems: data })
      })
      .catch(e => console.log(e))
  }

  getCompanies = () => {
    const loadOptions = api.getAllEmployers()
      .then(data => data.map(item => ({ value: item.id, label: item.name })))
      .catch(e => console.log(e))

    return loadOptions
  }

  handleMobileChange = (event) => {
    const regex = /^[0-9\b]+$/

    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({ telephoneNumber: event.target.value.trim() })
    }
  }

  handleVehicleRegChanges = (event) => {
    const regex = /^[0-9A-Za-z]*$/
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({ vehicleRegistration: event.target.value.trim().toUpperCase() })
    }
  }

  handleEmployerDropdownChange = (event) => {
    if (event) {
      this.setState({ employerId: parseInt(event.value, 10) })
      if (event.value !== 0) {
        this.setState({ employerName: event.label })
      }
    }
  }

  handleJobRoleDropdownChange = (event) => {
    if (!event.target.value) {
      this.setState({ jobRoleId: 0 })
    } else {
      this.setState({ jobRoleId: parseInt(event.target.value, 10) })
    }
  }

  checkEmailExists = (email) => {
    if (email && email.includes('@')) {
      return api.CheckEmailUsernameExistsAndActive(email)
        .then((res) => {
          if (res.status === 404 || res.status === 401) {
            this.setState({ emailAvailable: false })
            return
          }
          this.setState({ emailAvailable: !res })

          return res
        })
    }
  }

  checkAllFieldsValid = () => {
    const {
      forename, surname, telephoneNumber, employerId, jobRoleId, email, emailAvailable
    } = this.state

    let empId = employerId

    if (document.getElementsByClassName('ln-c-autocomplete__input')) {
      const elements = document.getElementsByClassName('ln-c-autocomplete__input')
      if (elements[0]) {
        empId = elements[0].defaultValue
      }
    }

    if (forename === ''
      || surname === ''
      || telephoneNumber === ''
      || jobRoleId === 0
      || empId === ''
      || empId === 0
      || (email === undefined ? true : !email.includes('@'))
      || (!document.querySelector('[aria-label="Clear the field"]'))
      || !emailAvailable
    ) {
      return false
    }
    return true
  }

  handleTextChange = (e, key) => {
    const state = {}
    const value = toTitleCase(e.target.value)

    state[key] = value
    this.setState(state)
  }

  handleEmailChange = (event) => {
    const regex = /^[0-9A-Za-z@\-_.]*$/

    this.setState({ emailBlured: false })

    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({ email: event.target.value })
    }
  }

  handleSubmit = async () => {
    const { history } = this.props
    if (this.checkAllFieldsValid()) {

      const { email } = this.state
      const emailExists = await this.checkEmailExists(email)

      if (!emailExists) {
        this.siteContractorDetails()
        history.push('/register/username')
      }
    } else {
      history.push('/register' )
    }
  }

  siteContractorDetails = () => {
    const {
      forename,
      surname,
      email,
      employerId,
      jobRoleId,
      telephoneNumber,
      vehicleRegistration,
      employerName
    } = this.state

    const { contractor, siteContractor } = this.props

    const contractorView = {
      forename,
      surname,
      email,
      employerId,
      jobRoleId,
      telephoneNumber,
      vehicleRegistration,
      employerName,
      acceptedTCs: contractor !== null ? contractor.acceptedTCs : false
    }

    siteContractor(contractorView)
  }

  setEmailBlur = () => {
    const { email } = this.state
    if (email.includes('@')) {
      this.checkEmailExists(email).then((exists) => {
        this.setState({ emailBlured: true, errorMsg: '', emailAvailable: !exists }, this.handleEmailError)
      })
    } else {
    this.setState({ emailBlured: true, errorMsg: '' }, this.handleEmailError)
    }
  }

  handleEmailError = () => {
    const { emailBlured, email, emailAvailable } = this.state
    let errorMsg = ''

    if (!email.includes('@')) {
      errorMsg = 'must include the @ symbol'
    }
    if (!emailAvailable) {
      errorMsg = 'is already in use'
    }
    if (email === '') {
      errorMsg = ''
    }

    if (emailBlured && errorMsg !== '') {
      return (
        <FormGroup
          name="formEmailError"
          error={`Your email address ${errorMsg}`}
        >
          <span />
        </FormGroup>
      )
    }
    return null
  }

  render() {
    const {
      forename,
      surname,
      email,
      jobTitleListItems,
      vehicleRegistration,
      telephoneNumber,
      jobRoleId,
      employerName,
      emailBlured
    } = this.state

    const clearCompanyButtonClicked = async () => {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      await delay(25)
      this.setState({ employerId: 0, employerName: undefined })
      this.forceUpdate()
    }

    const clearCompanyButton = document.querySelector('[aria-label="Clear the field"]')

    if (clearCompanyButton) {
      clearCompanyButton.addEventListener('click', clearCompanyButtonClicked)
    }

    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">
        <h2>Register</h2>
        <Container size="xs">
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.forceUpdate()}>
            <span className="ln-c-label">First name</span>
            <input
              id="txtForename"
              type="text"
              className="ln-c-text-input"
              value={forename}
              onChange={e => this.handleTextChange(e, 'forename')}
              maxLength="50"
            />
          </div>
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.forceUpdate()}>
            <span className="ln-c-label">Last name</span>
            <input
              id="txtSurname"
              type="text"
              className="ln-c-text-input"
              value={surname}
              onChange={e => this.handleTextChange(e, 'surname')}
              maxLength="50"
            />
          </div>
          <div className="ln-c-form-group ln-o-grid__item ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.forceUpdate()}>
            <span className="ln-c-label">Email</span>
            {emailBlured && this.handleEmailError()}
            <input
              id="txtEmail"
              type="text"
              className="ln-c-text-input"
              value={email}
              onChange={this.handleEmailChange}
              onBlur={() => this.setEmailBlur()}
              maxLength="250"
            />
            <div className="ln-u-margin-top">
              <Body1>We&#39;ll send you an email to verify this address before you can log in.</Body1>
            </div>
          </div>
          <div className="ln-o-grid__item ln-u-soft-right" onFocus={() => this.forceUpdate()}>
            <AsyncAutocompleteField
              icon={<Search />}
              name="company"
              label="Company"
              required
              defaultSelectedOption={{ label: employerName }}
              minChars={3}
              loadOptions={() => this.getCompanies()}
              onSelect={e => this.handleEmployerDropdownChange(e)}
            />
          </div>
          <SelectField
            className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right"
            name="jobRole"
            label="Job title"
            required
            value={jobRoleId}
            options={jobTitleListItems.map(item => ({ key: item.id, value: item.id, label: item.name }))}
            onChange={e => this.handleJobRoleDropdownChange(e)}
            onBlur={() => this.forceUpdate()}
            onFocus={() => this.forceUpdate()}
          />
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.forceUpdate()}>
            <span className="ln-c-label">Mobile number</span>
            <input
              id="txtTelephoneNumber"
              type="text"
              className="ln-c-text-input"
              value={telephoneNumber}
              onChange={this.handleMobileChange}
              maxLength="20"
            />
          </div>
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.forceUpdate()}>
            <span className="ln-c-label">Vehicle registration (optional)</span>
            <input
              id="txtRegistration"
              type="text"
              className="ln-c-text-input"
              value={vehicleRegistration}
              onChange={this.handleVehicleRegChanges}
              maxLength="20"
            />
          </div>
          <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
            <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
              <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" type="button" onFocus={() => this.forceUpdate()} onClick={this.handleSubmit} disabled={!this.checkAllFieldsValid()}>Next</FilledButton>
            </div>
          </div>
        </Container>
      </Container>
    )
  }
}

RegistrationForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  siteContractor: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  contractor: PropTypes.shape({
    forename: PropTypes.string,
    surname: PropTypes.string,
    jobRoleId: PropTypes.number,
    telephoneNumber: PropTypes.string,
    employerId: PropTypes.number,
    carRegistration: PropTypes.string
  })
}

RegistrationForm.defaultProps = {
  contractor: ''
}
export default RegistrationForm
