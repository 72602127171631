import React from 'react'
import PropTypes from 'prop-types'
import { Container, TextButton } from '@jsluna/react'

import signInBadge from '../../assets/SignInConfirmBadge.gif'
import signOutBadge from '../../assets/SignOutConfirmBadge.png'

import LoadingOverlay from '../LoadingOverlay'
import Feedback from '../Feedback'
import { AppId } from '../../utils/constants'
import api from '../../utils/api'

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFeedback: false,
      isLoading: true
    }
  }

  componentDidMount() {
    const { setHeader, setSignOutHeader, history, user } = this.props
    setHeader()
    setSignOutHeader(false)

    if (history.location.state && history.location.state.signIn === false) {
      api.showFeedback(AppId, user.username)
        .then(res => {
          this.setState({ showFeedback: res.show, isLoading: false })
        })
    }
  }

  handleDone = (e) => {
    e.preventDefault()

    const { history } = this.props

    if (history.location.state && history.location.state.signIn) {
      history.push('/signout')
    }
    else if (history.location.state && history.location.state.signIn === false) {
      history.push('/signin')
    }
  }

  render() {
    const { showFeedback, isLoading } = this.state
    const { user, history } = this.props
    const isSignOut = history.location.state && history.location.state.signIn === false
    
    var badge =  signInBadge
    var details = ''
    var signInMode = ''

    if (history.location.state && history.location.state.signIn) {
      details = (<>
        <div>Hi {user.forename},</div>
        <div>have a good day</div>
      </>)
      badge = signInBadge
      signInMode = 'In'
      if (isLoading) {
        this.setState({ isLoading: false })
      }
    }
    else if (isSignOut) {
      details = `See ya later ${user.forename}`
      badge = signOutBadge
      signInMode = 'Out'
    }

    if (isLoading) {
      return <LoadingOverlay hasBackground text={`Signing ${signInMode}...`} />
    }

    return (
      <React.Fragment>
        {isSignOut &&
          <Feedback
            appId={AppId}
            email = {user.username}
            headerText={'How was Sign-In Book?'}
            bodyText={'We want to know how you found using Sign-In Book today, so we can learn from your feedback and improve the tools you use.'}
            showFeedback={showFeedback}
          />
        }
        <div className='c-badge-background' />
        <Container className='ln-u-text-align-center u-no-scroll__vertical'>
          <div className='c-badge-container'>
            <img src={badge} alt='Sign-in Badge' className='c-badge-gif' />
          </div>
          <div className='c-badge-details'>{details}</div>
          <div className='c-badge-btn'>
            <TextButton onClick={this.handleDone}>Done</TextButton>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

ConfirmationPage.propTypes = {
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.shape({
    forename: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
}

export default ConfirmationPage
