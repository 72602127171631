import React, { Component } from 'react'
import parse from 'html-react-parser'
import api from '../../utils/api'

class Text extends Component {
  constructor() {
    super()
    this.state = {
      TCs: null
    }
  }

  async componentWillMount() {
    const TCs = await api.getAllTermsAndConditions()
    this.setState({ TCs })
  }

  render() {
    const { TCs } = this.state
    return (
      <div>
        {TCs && TCs.filter(x => x.type !== 'biometricPolicy').map(tc => (
          <div key={tc.type}>
            <h3>{tc.title}</h3>
            <div>{parse(tc.text)}</div>
          </div>
        ))}
      </div>
    )
  }
}

export default Text
