import React from 'react'
import { withRouter } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import logger from '../../utils/logger'

class ErrorPage extends React.Component {
  componentDidMount() {
    const { setHeader } = this.props
    setHeader()
  }

  logError = () => {
    const errMessage = `An error occurred in page ${document.referrer}`
    logger.logError(errMessage)
  }

  render() {
    return (
      <div className="ln-u-soft">
        <div className="ln-o-grid ln-o-grid--matrix">
          <div className="ln-o-grid__item ln-u-1/3@xs ln-u-1/5@ss ln-u-1/1" />
          <div className="ln-o-grid__item ln-u-1/3@xs ln-u-3/5@ss ln-u-1/1">
            <div className="ln-u-text-align-center ln-u-push-top ln-u-soft-bottom">
              <img src="/baguette-background-min.png" alt="baguette-img" className="error-image" />
              <h1 className="ln-u-color-orange error-message">
                Oh,<br />crumbs!
              </h1>
              <div className="error-instructions">
                <p>Something&apos;s gone wrong, go back to try again.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ln-o-grid__item ln-u-1/3@xs ln-u-1/1" />
      </div>
    )
  }
}

ErrorPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  setHeader: PropTypes.func.isRequired,
}

export default withRouter(ErrorPage)
