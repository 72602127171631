import React from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
  Password,
  FilledButton,
  Text,
  FormGroup,
  FieldInfo
} from '@jsluna/react'
import api from '../../utils/api'
import EmailConfirmationModal from '../Email/EmailConfirmationModal'
import LoadingOverlay from '../LoadingOverlay'

class UsernamePassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: '',
      passwordFieldBlured: false,
      confirmPasswordBlured: false,
      isRememberMeChecked: false,
      error: null,
      isModalOpen: false,
      loading: false
    }
  }

  componentWillMount() {
    const {
      setHeader, contractor, rememberMe, setSignOutHeader
    } = this.props
    setHeader()
    setSignOutHeader(false)

    if (!contractor) {
      window.location.href = '/register'
    }
    this.setState({ isRememberMeChecked: rememberMe })
  }

  checkAllFieldsValid = () => {
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol
    const {
      password, confirmPassword
    } = this.state
    const { contractor } = this.props
    if (password === ''
      || confirmPassword === ''
      || (password !== confirmPassword)
      || (regex.test(password)
        || contractor.employerId === 0)
    ) {
      return false
    }
    return true
  }

  handleTextChange = (e, key) => {
    const state = {}
    const { value } = e.target

    state[key] = value
    this.setState(state)
  }

  setPasswordBlur = () => {
    this.setState({ passwordFieldBlured: true })
  }

  setConfirmPasswordBlur = () => {
    this.setState({ confirmPasswordBlured: true })
  }

  passwordFieldError = () => {
    const { password, passwordFieldBlured } = this.state
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol

    if (regex.test(password) && passwordFieldBlured) {
      return (
        <FormGroup
          name="formPassword"
          error="Password must be at least 8 characters and consist of a number, uppercase letter and special character"
        >
          <span />
        </FormGroup>
      )
    }
    return null
  }

  confirmPasswordFieldError = () => {
    const { password, confirmPassword, confirmPasswordBlured } = this.state

    if (confirmPassword !== password && confirmPasswordBlured) {
      return (
        <FormGroup
          name="formConfirmPassword"
          error="Your passwords don't match"
        >
          <span />
        </FormGroup>
      )
    }
    return null
  }

  employerNameError = () => {
    const { contractor } = this.props
    if (contractor.employerId === 0) {
      return (
        <FormGroup
          name="test"
          error="Please select an employer from the dropdown on the previous page"
        >
          <span />
        </FormGroup>
      )
    }
    return null
  }

  handleRemeberMeChange = () => {
    const { isRememberMeChecked } = this.state
    this.setState({ isRememberMeChecked: !isRememberMeChecked })

    const { setRememberMe } = this.props
    setRememberMe(!isRememberMeChecked)
  }

  openEmailConfirmationModal = () => {
    const { isModalOpen } = this.state
    const { history, contractor } = this.props
    return (
      <EmailConfirmationModal isModalOpen={isModalOpen} history={history} email={contractor.email} />
    )
  }

  handleSubmit = () => {
    const { contractor } = this.props
    const {
      forename,
      surname,
      email,
      telephoneNumber,
      vehicleRegistration,
      employerId,
      jobRoleId,
      acceptedTCs
    } = contractor

    const { password } = this.state
    const details = {
      forename,
      surname,
      telephoneNumber,
      carRegistration: vehicleRegistration,
      employerId,
      jobRoleId,
      username: email,
      email,
      password,
      acceptedTCs
    }
    this.setState({ loading: true })
    api.registerContractor(details)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isModalOpen: true, loading: false })
        } else {
          this.setState({ isModalOpen: false, loading: false, error: 'Unable to register' })
        }
      })
      .catch(error => this.setState({ error, loading: false }))
  }

  render() {
    const {
      password,
      confirmPassword,
      error,
      loading
    } = this.state

    const { contractor } = this.props

    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">
        {this.openEmailConfirmationModal()}
        <h2>Almost there...</h2>
        <Container size="xs">
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right">
            <Text className="u-wrap-word" id="txtUsername" typeStyle="body-2">
              <h6>Your username: </h6>
              {contractor.email}
            </Text>
          </div>
        </Container>
        <Container size="xs">
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right">
            <label htmlFor="txtPassword" className="ln-c-label">Password</label>
            {this.passwordFieldError()}
            <Password
              className="ln-c-text-input"
              name="txtPassword"
              value={password}
              onBlur={() => this.setPasswordBlur()}
              onChange={e => this.handleTextChange(e, 'password')}
            />
          </div>
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/2@xs ln-u-soft-right">
            <label htmlFor="txtConfirmPassword" className="ln-c-label">Confirm password</label>
            {this.confirmPasswordFieldError()}
            <Password
              className="ln-c-text-input"
              name="txtConfirmPassword"
              value={confirmPassword}
              onBlur={() => this.setConfirmPasswordBlur()}
              onChange={e => this.handleTextChange(e, 'confirmPassword')}
            />
          </div>
          <div className="ln-u-soft-left ln-u-soft-top">{this.employerNameError()}</div>
          <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
            <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
              <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" type="button" onClick={this.handleSubmit} disabled={!this.checkAllFieldsValid()}>Register</FilledButton>
              {error
                && <FieldInfo error>{error}</FieldInfo>
              }
            </div>
          </div>
        </Container>
        {loading
              && <LoadingOverlay hasBackground text="Registering your account..." />
            }
      </Container>
    )
  }
}

UsernamePassword.propTypes = {
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setRememberMe: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool,
  contractor: PropTypes.shape({
    forename: PropTypes.string,
    surname: PropTypes.string,
    jobRoleId: PropTypes.number,
    telephoneNumber: PropTypes.string,
    employerId: PropTypes.number,
    carRegistration: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

UsernamePassword.defaultProps = {
  rememberMe: false,

}

export default UsernamePassword
