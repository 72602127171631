import React, { Component } from 'react'
import {
  Container, Card, Display4, Display2, FlagWrapper, FlagComponent, FlagBody, Text
} from '@jsluna/react'
import { Sainsburys, withLabel } from '@jsluna/images'
import { ErrorCircle } from '@jsluna/icons'
import LoadingOverlay from '../../LoadingOverlay'
import api from '../../../utils/api'

class EmailVerification extends Component {
  constructor() {
    super()

    this.state = {
      isLoading: false,
      isError: false,
      isSuccess: false,
      tokenExpired: false
    }
  }

  componentDidMount() {
    this.confirmEmail()
  }

  confirmEmail = () => {
    const search = new URLSearchParams(window.location.search)
    const userId = search.get('userId')
    const token = search.get('token')

    this.setState({ isLoading: true })

    api.confirmEmail(userId, token).then((res) => {
      if (res.succeeded) {
        this.setState({ isLoading: false, isSuccess: true, isError: false, tokenExpired: false })
      } else if (res.errors && res.errors[0].code === 'InvalidToken') {
        this.setState({ isLoading: false, isSuccess: false, isError: false, tokenExpired: true })
      } else {
        this.setState({ isLoading: false, isSuccess: false, isError: true, tokenExpired: false })
      }
    }).catch(() => { this.setState({ isLoading: false, isSuccess: false, isError: true, tokenExpired: false }) })
  }

  loginRedirect = () => {
    window.location.href = `${window.location.origin}\\login`
  }

  render() {
    const SainsburysLogo = withLabel(Sainsburys)
    const { isLoading, isError, isSuccess, tokenExpired } = this.state
    let error = false

    if (isError || tokenExpired) {
      error = true
    }

    return (
      <Container size="xs" className="ln-o-page ln-u-bg-color-white">
        <Card hard>
          <div className="ln-u-text-align-center ln-u-padding-top*2 ln-u-padding-bottom*2">
            <SainsburysLogo label="Sainsburys Group plc" width="200px" />
            <div className="ln-u-bg-color-orange ln-u-margin-top ln-u-padding-top*3 ln-u-padding-bottom*3">
              <Display4 className="ln-u-color-white">Digital sign-in book</Display4>
            </div>
          </div>
          {error
            && (
              <div>
                <div className="ln-u-soft">
                  <FlagWrapper className="ln-u-color-red">
                    <FlagComponent alignment={isError ? 'top' : ''}>
                      <div className="ln-u-padding-right ln-u-padding-top">
                        <ErrorCircle size="large" />
                      </div>
                    </FlagComponent>
                    <FlagBody alignment={isError ? '' : 'top'}>
                      <div className={isError ? 'ln-u-padding-top*1/2' : 'ln-u-padding-top'}>
                        <Display2>{isError ? 'Sorry, there\'s been a problem verifying your email address' : 'Email link has expired'}</Display2>
                      </div>
                    </FlagBody>
                  </FlagWrapper>
                  {tokenExpired
                    && (
                      <div className="ln-u-color-black ln-u-padding-top*2">
                        <Text>{'Try and log in to the Sign In Book app and we\'ll send you another link to validate your email address.'}</Text>
                      </div>
                    )}
                  {isError
                    && (
                      <div className="ln-u-margin-top*3">
                        <p className="ln-u-text-decoration-underline" onClick={() => this.confirmEmail()}>Try to verify again</p>
                      </div>
                    )}
                </div>
              </div>
            )
          }
          {isSuccess
            && (
              <div>
                <div className="ln-u-soft">
                  <Display2 className="ln-u-hard">Thank you - your email has been verified</Display2>
                  <div className="ln-u-margin-top*3">
                    <p>You can now log into the app</p>
                    <p>Click <a className='custom-text-button' onClick={this.loginRedirect}>here</a> to access the Log in page</p>
                  </div>
                </div>
              </div>
            )
          }
          {isLoading
            && <LoadingOverlay hasBackground text="Confirming your email..." />
          }
        </Card>
      </Container>
    )
  }
}

export default EmailVerification
