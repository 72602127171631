import React from 'react'
import { Modal, ModalHeading } from '@jsluna/modal'
import { Body1 } from '@jsluna/typography'
import { FilledButton, CancelButton } from '@jsluna/button'

class ThanksMessage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: true
		}
	}

	handleClose = () => {
		this.setState({ isOpen: false })
	}

	render() {
		const { isOpen } = this.state

		return (
			<Modal small hideCloseButton open={isOpen} headingId="info-modal">
				<div className="ln-u-text-align-right">
					<CancelButton
						className="feedback-closeButton"
						onClick={this.handleClose}
					/>
				</div>
				<ModalHeading element="h4">
					<div className="ln-u-text-align-center">
						<span
							aria-label="clapping hands"
							role="img"
							className="feedback-emoji"
						>
							👏
						</span>
						<br />
						Thank you
					</div>
				</ModalHeading>
				<Body1>
					Thanks for taking the time to send your comments. We rely on hearing
					from our colleagues to continue to prioritise making improvements in
					the coming releases.
				</Body1>
				<FilledButton
					fullWidth
					className="feedback-submitButton ln-u-margin-top*3"
					onClick={this.handleClose}
				>
					Close
				</FilledButton>
			</Modal>
		)
	}
}

export default ThanksMessage