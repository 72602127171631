import React from 'react'
import { PropTypes } from 'prop-types'

import {
  Container,
} from '@jsluna/react'

const FooterWithButton = (props) => {
  const { button } = props
  return (
    <Container className="c-footer__top-shadow">
      <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top ln-u-margin-top">
        <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs ln-u-1/2@sm">
          <div className="ln-u-push-bottom">
            {button}
          </div>
        </div>
      </div>
    </Container>
  )
}

FooterWithButton.propTypes = {
  button: PropTypes.node.isRequired,
}


export default FooterWithButton
