import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  OutlinedButton,
} from '@jsluna/react'

class SiteButton extends Component {
  handleSiteSelection = () => {
    const { onClick, site } = this.props
    onClick(site)
  }

  populateButtons = () => {
    const { site } = this.props
    if (site) {
      return (
        <div className="ln-u-soft-bottom">
          <OutlinedButton fullWidth onClick={this.handleSiteSelection}>{`${site.name} (${site.siteType})`}</OutlinedButton>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div className="ln-u-soft-bottom">
        {this.populateButtons()}
      </div>
    )
  }
}

SiteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  site: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    siteType: PropTypes.string.isRequired
  }).isRequired,
}

export default SiteButton
