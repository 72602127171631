import { HubConnectionBuilder } from '@aspnet/signalr'
import apiBaseUrl from './apiConfig'
import { getToken } from './storage'

const stopSignalRConnection = (connection, callback) => {
  connection.stop()
  if (callback) {
    callback()
  }
}

const bindSignalRConnection = (connection, callback) => {
  connection.on('broadcastMessage', () => stopSignalRConnection(connection, callback))
}

const ConfigureSignalRConnection = (callback, visitId) => {
  const hubUrl = 'authorisepass'
  const token = getToken()
  const connection = new HubConnectionBuilder() // establish signalr connection with backend hub
    .withUrl(`${apiBaseUrl}/${hubUrl}?access_token=${JSON.parse(token).token}`)
    .build()

  bindSignalRConnection(connection, callback) // bind signalr connection with method in hub in backend
  if (connection) {
    connection.start()
      .then(async () => {
        await connection.invoke('AddToGroup', visitId)
        return connection
      })
      .catch((error) => {
        throw error
      })
  }
}

export { ConfigureSignalRConnection, stopSignalRConnection }
