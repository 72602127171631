import React from 'react'
import { PropTypes } from 'prop-types'
import { Container, Modal, ModalHeading, FilledButton } from '@jsluna/react'
import Title from '../../PageTitle'
import api from '../../../utils/api'
import PassQuestionCarousel from '../PassQuestionCarousel'
import LoadingOverlay from '../../LoadingOverlay'

class PassQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      loading: true,
      hasError: false
    }
  }

  componentWillMount() {
    const {
      setHeaderBack,
      setSignOutHeader,
      selectedPass
    } = this.props

    setHeaderBack()
    setSignOutHeader(true)

    api.getPassQuestionById(selectedPass.id)
      .then((res) => {
        this.setState({ questions: res, loading: false })
      })
  }

  onError = () => {
    this.setState({ hasError: true })
  }

  renderCarousel = () => {
    const { history } = this.props
    const { questions } = this.state
    if (questions.length > 0) {
      return <PassQuestionCarousel questions={questions} history={history} onError={this.onError} />
    }
    return null
  }

  render() {
    const { selectedWorkOrder, selectedPass = {} } = this.props
    const { loading, hasError } = this.state

    return (
      <div>
        <Modal
          handleClose={() => this.setState({ hasError: false })}
          open={hasError}
        >
          <ModalHeading element="h3">
            Unable to save pass
          </ModalHeading>
          There has been a problem saving your pass, please try again
        </Modal>
        <Title workOrderNumber={selectedWorkOrder} text={selectedPass.name} />
        <Container>
          <Container className="ln-u-soft-sides">
            {/* <ProgressBar value={80} /> */}
            {this.renderCarousel()}
          </Container>
        </Container>
        {loading
          && <LoadingOverlay hasBackground />
        }
      </div>
    )
  }
}

PassQuestions.propTypes = {
  setHeaderBack: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  selectedWorkOrder: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedPass: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default PassQuestions
