import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as burgerMenu } from 'redux-burger-menu'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import contractor from './contractor/reducer'
import device from './device/reducer'
import header from './header/reducer'
import rememberMe from './remember/reducer'
import user from './user/reducer'
import loggedInUser from './loggedInUser/reducer'
import passTypes from './passTypes/reducer'
import sainsburysSites from './site/reducer'
import visit from './visit/reducer'
import workOrders from './workorders/reducer'
import signIn from './signIn/reducer'
import sagas from './sagas'
import task from './task/reducer'
import supplier from './supplier/reducer'
import taskTime from './taskTime/reducer'


const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const historyMiddleware = routerMiddleware(history)

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['burgerMenu', 'sainsburysSites', 'workOrders', 'visit', 'router','task','supplier','taskTime']
}

const rootReducer = combineReducers({
  router: connectRouter(history),
  contractor,
  device,
  header,
  rememberMe,
  user,
  burgerMenu,
  loggedInUser,
  sainsburysSites,
  signIn,
  visit,
  passTypes,
  workOrders,
  task,
  supplier,
  taskTime
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = createStore(persistedReducer, composeWithDevTools(compose(applyMiddleware(sagaMiddleware, historyMiddleware))))

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export { store, persistor, history }
