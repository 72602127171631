import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  Display3,
  Card,
  Container,
  List,
  ListItem,
  FilledButton,
  FormGroup,
  PasswordField
} from '@jsluna/react'

import SainsburysDSIBHeader from '../SainsburysDSIBHeader'
import LoadingOverlay from '../LoadingOverlay'
import api from '../../utils/api'
import logger from '../../utils/logger'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      passwordFieldBlured: false,
      confirmPasswordBlured: false,
      loading: false
    }
  }

  async componentWillMount() {
    const { history } = this.props

    const search = new URLSearchParams(window.location.search)
    const userId = search.get('userId')
    const token = search.get('token')
    const timeStamp = Math.floor(search.get('timeStamp'))
    const now = Date.now()
    let tokenValid = true

    await api.checkTokenIsValid(userId, 'Default', 'ResetPassword', token)
      .then((res) => {
        if (res === true) {
          tokenValid = true
        } else if (res.status === 400) {
          tokenValid = false
        }
      })
      .catch((error) => {
        logger.logError(error)
      })

    // email is over 24hrs old so now expired
    if ((now - timeStamp) >= 8.64e+7) { // 8.64e+7 = 24hrs in milliseconds
      history.push('/resetpassword/expired')
    } else if (!tokenValid && (now - timeStamp < 8.64e+7)) { // token is invalid but less than 24hrs old so therefore it's been used already
      history.push('/resetpassword/used')
    }
  }


  checkAllFieldsValid = () => {
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol
    const {
      password, confirmPassword
    } = this.state

    if (password === ''
      || confirmPassword === ''
      || (password !== confirmPassword)
      || (regex.test(password))
    ) {
      return false
    }
    return true
  }

  setPasswordBlur = () => {
    this.setState({ passwordFieldBlured: true })
  }

  setConfirmPasswordBlur = () => {
    this.setState({ confirmPasswordBlured: true })
  }

  passwordFieldError = () => {
    const { password, passwordFieldBlured } = this.state
    const regex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/ // must be min length of 8, 1 uppercase, 1 number and 1 symbol

    if (regex.test(password) && passwordFieldBlured) {
      return true
    }
    return false
  }

  confirmPasswordFieldError = () => {
    const { password, confirmPassword, confirmPasswordBlured } = this.state

    if (confirmPassword !== password && confirmPasswordBlured) {
      return true
    }
    return false
  }

  handleTextChange = (e, key) => {
    const state = {}
    const { value } = e.target

    state[key] = value
    this.setState(state)
  }

  handleSubmit = () => {
    const { history } = this.props
    this.setState({ loading: true })

    const { password } = this.state

    const search = new URLSearchParams(window.location.search)
    const userId = search.get('userId')
    const token = search.get('token')

    api.resetPassword(userId, token, password)
      .then((res) => {
        if (res.succeeded === true) {
          history.push('/resetsuccess')
        } else {
          history.push(`/reseterror?${search}`)
        }
        this.setState({ loading: false })
      }).catch(() => history.push(`/reseterror?${search}`))
  }

  render() {
    const { password, confirmPassword, loading } = this.state

    return (
      <div className="ln-u-bg-color-white ln-o-page">
        <SainsburysDSIBHeader />
        <Card>
          <Display3>Reset password</Display3>
          <Container className="ln-u-margin-top*2">
            <p className="ln-u-margin-bottom*2">Please enter your password in the following format:</p>
            <List>
              <ListItem className="ln-u-padding-ends">at least 1 uppercase letter</ListItem>
              <ListItem className="ln-u-padding-ends">at least 1 number</ListItem>
              <ListItem className="ln-u-padding-ends">at least 1 special character</ListItem>
              <ListItem className="ln-u-padding-ends">and be at least 8 characters long.</ListItem>
            </List>
            <div className="ln-c-form-group ln-u-1/2@xs">
              <FormGroup
                name="txtPassword"
                label="Password"
              >
                <PasswordField
                  name="txtPassword"
                  className={this.passwordFieldError() === true ? 'ln-c-form-group has-error' : ''}
                  value={password}
                  onBlur={() => this.setPasswordBlur()}
                  onChange={e => this.handleTextChange(e, 'password')}
                />
              </FormGroup>
              {this.passwordFieldError() === true
                && (
                  <FormGroup
                    name="txtPasswordError"
                    error="Enter password in the correct format"
                    className="u-margin-top__minus1rem"
                  >
                    <span />
                  </FormGroup>
                )
              }
            </div>
            <div className="ln-c-form-group ln-u-1/2@xs">
              <FormGroup
                name="txtConfirmPassword"
                label="Confirm Password"
              >
                <PasswordField
                  name="txtConfirmPassword"
                  className={this.confirmPasswordFieldError() === true ? 'ln-c-form-group has-error' : ''}
                  value={confirmPassword}
                  onBlur={() => this.setConfirmPasswordBlur()}
                  onChange={e => this.handleTextChange(e, 'confirmPassword')}
                />
              </FormGroup>
              {this.confirmPasswordFieldError() === true
                && (
                  <FormGroup
                    name="txtConfirmPasswordError"
                    error="The passwords must be the same"
                    className="u-margin-top__minus1rem"
                  >
                    <span />
                  </FormGroup>
                )
              }
            </div>
            <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary ln-u-1/2@xs" type="button" disabled={!this.checkAllFieldsValid()} onClick={() => this.handleSubmit()}>Submit</FilledButton>
          </Container>
        </Card>
        {loading
          && <LoadingOverlay hasBackground text="Resetting your password..." />
        }
      </div>
    )
  }
}

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

}

export default ResetPassword
