import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/react'
import{
  Alert,
  AlertIcon,
} from '@jsluna/alert'
import '@jsluna/alert/css/main.css'
import { ErrorCircle} from '@jsluna/icons'
import '../../sass/components/signout-notification.scss'

class MerchandiserSignIn extends React.Component {
  constructor(props) {
    super(props)
   } 
  componentWillMount = () => {
    const {
      setHeaderBurger,
      setHeaderBack,
      setSignOutHeader,
      match
    } = this.props
    const { withbackbutton } = match.params

    if (withbackbutton === 'true') {
      setHeaderBack()
    } else {
      setHeaderBurger()
    }
    setSignOutHeader(true)
}
   handleSignOut=()=>{
    const {history} = this.props ; 
    history.push('/SignIn')                                                                                 
    }
 
  render() {
    const { signedInSite } = this.props
      return (
            <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*4">
              <Container size="xs">
                <div className="ln-c-form-group ln-o-grid__item ln-u-1/1@xs ln-u-soft-right ln-u-margin-bottom*0px">
                  <h3 className="SignIn">You're signed in </h3>
                  <h6 className="SignIn">{signedInSite ? signedInSite.name : ''} </h6>
                </div>  
                
                <div className="ln-o-grid__item ln-u-soft-right signout-notification">
                  <Alert variant="warning">
                    <AlertIcon>
                     <ErrorCircle id="banner-success-icon"/>
                    </AlertIcon>
                    <h7><b>You must ask a colleague  
                    to check your identification
                    badge before starting work.</b></h7>
                  </Alert>
                </div>
                
                <div className="ln-o-grid__item ln-u-soft-right signout-notification">
                 <p> Please remember to sign out when you leave the site. </p>  
                </div>
              </Container>
            </Container>
          )
        }
    }  

 MerchandiserSignIn.propTypes = {
    signedInSite: PropTypes.shape({
    name: PropTypes.string.isRequired
    }),
    setHeaderBurger: PropTypes.func.isRequired,
    setHeaderBack: PropTypes.func.isRequired,
    setSignOutHeader: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
      withbackbutton: PropTypes.string,
      }).isRequired,
    }).isRequired
}
    
 MerchandiserSignIn.defaultProps = {
    signedInSite: {
        name: ''
    }
}

export default MerchandiserSignIn
