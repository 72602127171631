export const logLevel = {
  none: { name: 'none', index: 0 },
  information: { name: 'information', index: 1 },
  warning: { name: 'warning', index: 2 },
  error: { name: 'error', index: 3 }
}

export const passQuestionResponseTypes = {
  buttons: 1,
  freeText: 2,
  expiry: 3,
  endPass: 4,
  endWorkOrder: 5
}

export const visitPassActionTypes = {
  submitted: 1,
  approved: 2,
  rejected: 3
}

export const siteTypes = {
  store: 1,
  convenience: 2,
  depot: 3
}

export const signInOutType = {
  password: 4
}

export const AppId = 2

export default logLevel
