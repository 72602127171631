export const actions = {
    set: 'SET::SUPPLIER',
    setName: 'SET::SUPPLIERNAME',
    get: 'GET::SUPPLIER'
  }
  
  export const setSupplier = supplier => ({ type: actions.set, registeredSupplier: supplier })
  export const setSupplierName = supplierName => ({ type: actions.setName, registeredSupplierName: supplierName })
  export const getSupplier = state => state.supplier.registeredSupplier
  
  export default actions
  