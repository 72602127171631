import actions from './actions'

const device = (state = { deviceType: null }, action) => {
  switch (action.type) {
    case actions.setType:
      return { ...state, deviceType: action.deviceType }
    default:
      return state
  }
}

export default device
