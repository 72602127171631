import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Container, FilledButton, FormGroup
} from '@jsluna/react'
import FireSafetyPolicy from '../../policyWording/FireSafetyPolicy'
import WithFooter from '../Footer'
import LoadingOverlay from '../LoadingOverlay'
import AlreadySignedInModal from '../AlreadySignedInModal'
import redirectUser from '../../utils/routeHelper'
import api from '../../utils/api'
import logger from '../../utils/logger'


class FirePolicy extends React.Component {
  
  constructor(props) {
    super(props)
    const { setHeader, setSignOutHeader } = props
    this.state = {
      gpsError: false,
      responseObj:''
    }
    setHeader()
    setSignOutHeader(false)
  }

  componentDidMount = () => {
    api.getUserState()
    .then((result) => {
      if( result !== null){
        let response = result;
       this.setState({responseObj:response})
      }
    })
  }


  getFooter = () => (
    <Container size="xs">
      <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
        <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
          <div className="ln-u-push-bottom">
            <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" onClick={this.handleAcceptClick} fullWidth>Accept</FilledButton>
          </div>
        </div>
      </div>
    </Container>
  )

 handleAcceptClick = () => {
    const { responseObj } = this.state
    const { history, setRAMSDecline, employerId } = this.props
    setRAMSDecline('NotSet')
    const isMerchandiserEnabled = responseObj !== null ? responseObj.contractorState.contractor.isMerchandiserEnabled : false ;
    if(isMerchandiserEnabled){
    history.push('/supplier')
  }
  else {
    if (window.navigator.geolocation) {
      api.areWorkOrdersRequried(employerId)
      .then((res) => {
        console.log(res);
        if(res){
          history.push('/ramspolicy')
        }
        else{
          window.navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError)
        }
      })
      .catch(err => logger.logError(err))
     // window.navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError)
    //  history.push('/ramspolicy')
    }
  }
}

  locationSuccess = (location) => {
    const { requestSignIn } = this.props
    requestSignIn(location.coords.latitude, location.coords.longitude)
  }

  locationError = (error) => {
    const { message } = error
    let msg = message
    if (error.code === 2) {
      msg = 'GPS is currently unavailable'
    }

    this.setState({ gpsError: true, gpsErrorText: msg })
  }


  showGPSError = () => {
    const { gpsError, gpsErrorText } = this.state
    if (gpsError) {
      return <FormGroup error={gpsErrorText} />
    }
    return ''
  }

  render() {
   const { signInState, history, resetSignIn } = this.props
     return (
      <WithFooter className="ln-c-card" footer={this.getFooter()}>
      <AlreadySignedInModal isOpen={signInState.status.alreadySignedIn}
        title='Already Signed In'
        details='You are already signed in'
        okClick={() => {
          resetSignIn()
          redirectUser(history)
        }}
        />
        <Container size="xs">
          <div className="ln-u-push">
            {this.showGPSError()}
            <FireSafetyPolicy />
            {signInState.loading
              && <LoadingOverlay hasBackground text="Signing you in..." />
            }
          </div>
        </Container>
      </WithFooter>
    )
  }
}

FirePolicy.propTypes = {
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  requestSignIn: PropTypes.func.isRequired,
  resetSignIn: PropTypes.func.isRequired,
  setRAMSDecline: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  site: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired
  }),
  signInState: PropTypes.shape({
    loading: PropTypes.bool.isRequired
  }).isRequired
}

FirePolicy.defaultProps = {
  site: null
}

export default FirePolicy
