import actions from './actions'

const workOrders = (state = { siteWorkOrders: [] }, action) => {
  switch (action.type) {
    case actions.setWorkOrders:
      return { ...state, siteWorkOrders: action.siteWorkOrders }
    default:
      return state
  }
}

export default workOrders
