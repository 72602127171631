import { connect } from 'react-redux'
import WorkOrderHomePage from './WorkOrderHomePage'
import { setHeaderBurger, setHeaderSignOut } from '../../store/header/actions'

const mapStateToProps = state => ({
  signedInSite: state.sainsburysSites.site,
  selectedWorkOrder: state.visit.openVisit ? state.visit.openVisit.workOrderNumber : null,
  workOrders: state.workOrders.siteWorkOrders,
  passTypes: state.passTypes.passTypes,
  visitPasses: state.visit.openVisit ? state.visit.openVisit.visitPasses : null,
  hasPassesHandedBack: state.visit.openVisit.hasPassesHandedBack,
  visitId: state.visit.openVisit.id,
})

const mapDispatchToProps = dispatch => ({
  setBurgerHeader: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderHomePage)
